import { useState } from 'react';

// Our Components
import DataTable from 'components/DataTable/DataTable';
import ColumnItem from 'components/DataTable/ColumnItem';
import ColumnVerticalHeader from 'components/DataTable/ColumnVerticalHeader';
import StateAlert from 'components/Alert/StateAlert';
import { TertiaryButton } from 'components/Button/Button';

// Our Utils
import constructMiniAppLink from 'shared/utils/generatedLinks/constructMiniAppLink';
import copyToClipboard from 'shared/utils/events/copyToClipBoard';

function ReferralPartnerTable({ referralPartners }) {
	const [isAlertOpen, setIsAlertOpen] = useState(false);

	const firstNameKey = 'firstname';
	const lastNameKey = 'lastname';
	const emailKey = 'email';

	const columnsReferralPartner = [
		ColumnItem(firstNameKey, 'First name', 0, {
			width: 200,
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(lastNameKey, 'Last name', 0, {
			width: 200,
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(emailKey, 'Email', 0, {
			width: 350,
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem('actions', 'Copy Unique Link', 0, {
			width: 180,
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			),
			renderCell: ({ row }) => (
				<TertiaryButton
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setIsAlertOpen(true);

						const { magicToken } = row;

						const referralPartnerLink = constructMiniAppLink(
							'verify/referral-partner',
							magicToken
						);

						copyToClipboard(referralPartnerLink);
					}}
				>
					Copy
				</TertiaryButton>
			)
		})
	];

	return (
		<>
			<StateAlert
				isOpen={isAlertOpen}
				setIsOpen={setIsAlertOpen}
				message="Unique link copied to clipboard"
				variant="success"
			/>
			<DataTable
				columns={columnsReferralPartner}
				rows={referralPartners}
				rowsPerPageOptions={10}
				handleRowClick={(e, row) => {}}
				disableSelectionOnClick
			/>
		</>
	);
}

export default ReferralPartnerTable;
