import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// Our axios Instance
import axiosInstance from 'services/API/API';

// our Endpoints
import { verifyClientInvitation } from 'shared/api-urls';

// Our Query keys
import { VERIFIED_CLIENT_INVITE_DATA } from 'shared/query-keys';

/**
 * @typedef {Object} ClientInviteData
 * @property {number} id - The invitation ID
 * @property {number} inviteeId - ID of the person being invited
 * @property {string} invitedEmail - Email address of the invitee
 * @property {string} invitedName - Name of the invitee
 * @property {boolean} accepted - Whether the invitation has been accepted
 * @property {number} accountId - Associated account ID
 * @property {('PENDING'|'ACCEPTED'|'REJECTED')} status - Current status of the invitation
 * @property {string} invitedCode - Unique invitation code
 * @property {number} externalContactId - External contact reference ID
 */

/**
 * Hook to verify client invitation
 * @returns {import('react-query').UseQueryResult<ClientInviteData>}
 */
function useQueryVerifyClientInvite() {
	const { inviteCode } = useParams();

	// Construct Url for axios get call below
	const urlWithInviteCode = `${verifyClientInvitation + inviteCode}`;

	return useQuery(VERIFIED_CLIENT_INVITE_DATA, async () => {
		const response = await axiosInstance.get(urlWithInviteCode);

		/** @type {ClientInviteData} */
		const acceptedInviteData = response.data.data;

		return acceptedInviteData;
	});
}

export default useQueryVerifyClientInvite;
