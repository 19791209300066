import { useState, useMemo, useCallback, useEffect } from 'react';
import { useQueries } from 'react-query';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

// Our Components
import Loader from 'components/Loader';
import FileItem from 'components/FileUpload/FileItem';
import FilesList from 'components/FileUpload/UploadFile/FilesList';
import FileUpload from 'components/FileUpload/FileUpload';
import PreviewFile from 'components/FileUpload/UploadFile/PreviewFile';
import { SecondaryButton } from 'components/Button/Button';

// Our Hooks
import useCleanUpQueries from 'hooks/useCleanUpQueries';
import useMutateUploadBoxFile from 'hooks/integrations/box/useMutateUploadBoxFile';

// query keys
import { ERROR_MESSAGE_DATA, FILE_UPLOAD } from 'shared/query-keys';

function UploadFilesBox({ folderId }) {
	const uploadBoxFile = useMutateUploadBoxFile(folderId);

	const [files, setFiles] = useState([]);
	const [filesRejected, setFilesRejected] = useState([]);
	const [previewImage, setPreviewImage] = useState(null);
	const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [isUploading, setIsUploading] = useState(false);

	useCleanUpQueries([ERROR_MESSAGE_DATA, FILE_UPLOAD]); // When UploadLoanDocs ceases to exist it will clean up these queries

	const hasImageToPreview = previewImage !== null; // if previewImage is null then I know we don't have a preview item selected.
	const queryKeys = useMemo(() => files.map(({ key }) => key), [files]);
	const queryStatuses = useQueries(
		queryKeys.map((currentQueryKey) => ({
			queryKey: [FILE_UPLOAD, currentQueryKey],
			queryFn: () => true,
			enabled: false
		}))
	);

	const isSuccess = useMemo(
		() =>
			queryStatuses.length > 0 &&
			queryStatuses.every(
				(queryStatus) => queryStatus.status === 'success'
			),
		[queryStatuses]
	);

	const isError = useMemo(
		() =>
			queryStatuses.length > 0 &&
			queryStatuses.every(
				(queryStatus) => queryStatus.status === 'error'
			),
		[queryStatuses]
	);

	const isFetching = useMemo(
		() =>
			queryStatuses.length > 0 &&
			queryStatuses.every((queryStatus) => queryStatus.isFetching),
		[queryStatuses, isSuccess, isError]
	);

	// This controls whether the upload button shows upload or spinner
	// if isFetching status changes it will run. isFetching changes if all queries are marked as isFetching
	// if isSuccess changes it will run. isSuccess changes if all are in Success or none are
	// if isError changes it will run. isError changes if all are in error or none are
	useEffect(() => {
		setIsUploading(isFetching);
	}, [isFetching, isSuccess, isError]);

	useEffect(() => {
		if (showErrorAlert) {
			setTimeout(() => {
				setShowErrorAlert(false);
			}, 3000);
		}
	}, [showErrorAlert]);

	const updateAcceptedFiles = useCallback((data) => {
		setFiles(data);
	}, []);

	const updateRejectedFiles = useCallback((data) => {
		setFilesRejected(data);
	}, []);

	const removeFiles = useCallback(
		(file) => {
			// cook
			const newFilesList = files.filter(
				({ fileData: currentFile }) => currentFile !== file
			);
			updateAcceptedFiles(newFilesList);
		},
		[files]
	);

	const removeRejectedFiles = (file) => {
		let indexOfFile;
		// for rejected files we store both the File object and its associated error on why it as rejected
		// Because of that we iterate over the list of filesRejected and compare its currentFile to the file that was returned from the callback.
		// eslint-disable-next-line no-restricted-syntax
		for (const [idx, value] of filesRejected.entries()) {
			const { file: currentFile } = value;

			if (file === currentFile) {
				indexOfFile = idx;
				break;
			}
		}
		if (indexOfFile === undefined || indexOfFile === null) return;
		const before = filesRejected.slice(0, indexOfFile);
		const after = filesRejected.slice(indexOfFile + 1);
		updateRejectedFiles([...before, ...after]);
	};

	const acceptedFilesItems = useMemo(
		() =>
			files.map(({ fileData: currentFile, key: uniqueIdentifier }) => (
				<FileItem
					key={uniqueIdentifier}
					file={currentFile}
					setFileToPreview={(fileDetails) => {
						setPreviewImage(fileDetails);
						setIsPreviewEnabled(true);
					}}
					removeFile={removeFiles}
					fileId={uniqueIdentifier}
					isUploading={isUploading}
					handleUploadFile={uploadBoxFile.mutate}
				/>
			)),
		[files, isUploading]
	);

	const rejectedFileItems = useMemo(
		() =>
			filesRejected.map((currentFile) => (
				<FileItem
					key={uuidv4()}
					file={currentFile.file}
					error={currentFile.errors[0]}
					removeFile={removeRejectedFiles}
					isRejectedFile
				/>
			)),
		[filesRejected]
	);

	return (
		<>
			<FileUpload
				files={files}
				filesRejected={filesRejected}
				handleAcceptedFileCallBack={updateAcceptedFiles}
				handleRejectFileCallBack={updateRejectedFiles}
			/>

			<FilesList
				files={files}
				filesRejected={filesRejected}
				acceptedFilesItems={acceptedFilesItems}
				rejectedFileItems={rejectedFileItems}
			/>

			<Box>
				{!isUploading && (
					<SecondaryButton
						disabled={files.length < 1}
						onClick={() => {
							if (filesRejected.length > 0) {
								setShowErrorAlert(true);
							}
							if (!isSuccess) {
								setIsUploading(true);
							}
						}}
						sx={{ marginRight: 1 }}
					>
						Upload
					</SecondaryButton>
				)}

				{isUploading && <Loader />}
			</Box>
			<PreviewFile
				isPreviewEnabled={isPreviewEnabled}
				setIsPreviewEnabled={setIsPreviewEnabled}
				previewImage={previewImage}
				setPreviewImage={setPreviewImage}
				hasImageToPreview={hasImageToPreview}
			/>
		</>
	);
}

UploadFilesBox.propTypes = {
	folderId: PropTypes.string.isRequired
};

export default UploadFilesBox;
