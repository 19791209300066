import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTheme } from '@emotion/react';

// utils
import { dataIsValid } from 'shared/utils';

// Our Constants
import {
	ADVISOR_MANUAL,
	ADVISOR_INTEGRATION,
	ADVISOR_BATCH,
	REFERRAL_PARTNER,
	SELF,
	UNKNOWN
} from 'shared/constants/OnboardingSource/OnboardingSourceTypes';

const ONBOARDING_SOURCE_TYPE = {
	[ADVISOR_MANUAL]: (advisorName) => `${advisorName}`,
	[ADVISOR_INTEGRATION]: (advisorName) => `${advisorName}`,
	[ADVISOR_BATCH]: (advisorName) => `${advisorName}`,
	[REFERRAL_PARTNER]: (referralPartner) => {
		const { firstname, lastname } = referralPartner;
		return `${firstname} ${lastname}`;
	},
	[SELF]: 'Self Onboard',
	UNKNOWN: 'Unknown'
};

const getOnboardingSourceOutput = (
	onboardingSourceType,
	referralPartner,
	advisorName
) => {
	if (onboardingSourceType === SELF)
		return ONBOARDING_SOURCE_TYPE[onboardingSourceType];

	if (onboardingSourceType === REFERRAL_PARTNER)
		return ONBOARDING_SOURCE_TYPE[onboardingSourceType](referralPartner);

	return ONBOARDING_SOURCE_TYPE[onboardingSourceType](advisorName);
};

function SoraWalletOnboardingSource({
	onboardingSourceType,
	referralPartner,
	advisorName
}) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const onboardingSource = useMemo(() => {
		if (
			!dataIsValid(onboardingSourceType) ||
			onboardingSourceType === UNKNOWN
		)
			return null;

		return getOnboardingSourceOutput(
			onboardingSourceType,
			referralPartner,
			advisorName
		);
	}, [onboardingSourceType, referralPartner, advisorName]);

	if (!dataIsValid(onboardingSourceType) || onboardingSourceType === UNKNOWN)
		return <div style={{ display: 'none' }} />;

	return (
		<Typography
			variant="body2"
			sx={{
				margin: '8px 32px 0px 8px',
				color: primary.grey
			}}
		>
			Onboarding Source: {onboardingSource}
		</Typography>
	);
}

export default SoraWalletOnboardingSource;
