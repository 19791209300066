import { Helmet } from 'react-helmet-async';

// SignupForm
import SignupForm from 'components/Signup/SignupForm';

function SignupPage() {
	return (
		<>
			<Helmet>
				<title>Sign up</title>
			</Helmet>

			<SignupForm />
		</>
	);
}

export default SignupPage;
