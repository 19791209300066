import { Helmet } from 'react-helmet-async';

// Our Components
import LoginForm from 'components/LoginForm/LoginForm';

function LoginPage() {
	return (
		<>
			<Helmet>
				<title>Login</title>
			</Helmet>

			<LoginForm />
		</>
	);
}

export default LoginPage;
