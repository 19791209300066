import { useQuery } from 'react-query';

// Our Services
import BoxAPI from 'services/API/BoxAPI';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

// Our Query Keys
import { USER_BOX_FOLDER } from 'shared/query-keys';

// Constants
import { ADVISOR, DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

function useQueryGetUserBoxFolder() {
	const { data: profile } = useGetProfile();

	const { id, firstName, lastName, role } = profile;

	return useQuery(
		USER_BOX_FOLDER,
		async () => {
			const response = await BoxAPI.get('folder/user/', {
				params: {
					advisorFirstName: firstName,
					advisorLastName: lastName,
					userId: id
				}
			});
			return response.data;
		},
		{
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES,
			enabled: role === ADVISOR
		}
	);
}

export default useQueryGetUserBoxFolder;
