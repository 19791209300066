import { useMutation } from 'react-query';

// Our Hooks
import useMutateUnmaskUser from 'hooks/useMutateRemoveUserMask';

// Constants
import {
	AUTH_METHOD_EMAIL,
	AUTH_METHOD_GOOGLE
} from 'shared/constants/auth/authConstants';

const useMutateCommonAuth = () => {
	const unmaskUser = useMutateUnmaskUser();

	return useMutation(async ({ email, password, method }) => {
		// Validate inputs before proceeding
		if (method === AUTH_METHOD_EMAIL && (!email || !password)) {
			throw new Error(
				'Email and password are required for email authentication'
			);
		}
		if (![AUTH_METHOD_EMAIL, AUTH_METHOD_GOOGLE].includes(method)) {
			throw new Error('Invalid authentication method');
		}

		try {
			await unmaskUser.mutate({});
		} catch (e) {
			console.error(e);
		}
	});
};

export default useMutateCommonAuth;
