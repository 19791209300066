import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useState, useRef } from 'react';
import { useQueryClient } from 'react-query';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';

// Our Components
import Alert from 'components/Alert/index';
import Loader from 'components/Loader/index';
import Modal from 'components/Modal/Modal';
import { TertiaryButton } from 'components/Button/Button';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

// Our Query Keys
import { SUCCESS_MESSAGE_DATA } from 'shared/query-keys';

// utils
import copyToClipboard from 'shared/utils/events/copyToClipBoard';
import constructMiniAppLink from 'shared/utils/generatedLinks/constructMiniAppLink';

function ModalContent({
	version,
	primary,
	toggleModal,
	queryClient,
	advisorUrl
}) {
	const isSendEmailMode = version === 1;

	const qrCodeRef = useRef(null);

	if (isSendEmailMode) {
		return (
			<>
				<Box
					sx={{
						border: `1px solid ${primary.lightGrey}`,
						borderRadius: 3,
						padding: 2
					}}
				>
					<Typography
						id="modalBoxText"
						variant="body1"
						sx={{ lineHeight: '24px', color: primary.grey }}
					>
						As a part of the services I deliver as an advisor, I can
						help manage and optimize client liabilities (mortgages,
						auto loans etc.). If you&apos;d like me to review your
						liabilities and automatically generate recommendations,
						you can onboard here{' '}
						<a href={advisorUrl} target="_blank" rel="noreferrer">
							{advisorUrl}
						</a>
						. The onboarding process takes about 30 seconds, and
						after that we can set up a time to review your
						liabilities together.
					</Typography>
				</Box>
				<TertiaryButton
					sx={{ marginTop: 4 }}
					onClick={() => {
						toggleModal();
						const modalBoxText =
							document.querySelector('#modalBoxText').innerText;

						const formattedText = modalBoxText.replaceAll(
							'&apos;',
							"'"
						);

						copyToClipboard(formattedText);
						queryClient.setQueryData(
							SUCCESS_MESSAGE_DATA,
							'Copied text to clipboard.'
						);
					}}
				>
					Copy Text
				</TertiaryButton>
			</>
		);
	}

	const handleDownloadImage = async () => {
		const qrCodeElement = qrCodeRef.current;

		try {
			const canvas = await html2canvas(qrCodeElement);

			const jpgDataUrl = canvas.toDataURL('image/jpeg');

			// Create a temporary anchor element to download the image
			const a = document.createElement('a');
			a.href = jpgDataUrl;
			a.download = 'qr_code.jpg';
			a.click();

			toggleModal();
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e);
		}
	};

	// Qr code mode
	return (
		<>
			<Box
				sx={{
					padding: 2,
					width: '100%',
					display: 'flex',
					justifyContent: 'center'
				}}
			>
				<Box ref={qrCodeRef} sx={{ padding: 2 }}>
					<QRCode
						size={256}
						style={{ height: 256, width: 256 }}
						value={advisorUrl}
						viewBox="0 0 256 256"
					/>
				</Box>
			</Box>

			<Box
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					paddingTop: 2
				}}
			>
				<TertiaryButton
					onClick={handleDownloadImage}
					sx={{ marginTop: 2 }}
				>
					Download
				</TertiaryButton>
			</Box>
		</>
	);
}

function ProspectiveClientPage() {
	const SoraTheme = useTheme();
	const { primary, gradient } = SoraTheme.palette;

	const queryClient = useQueryClient();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalVersion, setModalVersion] = useState(null);
	const [causeRefresh, setCauseRefresh] = useState(0);

	const toggleModal = (version) => {
		if (isModalOpen) {
			setTimeout(() => setModalVersion(version), 200);
		} else {
			setModalVersion(version);
		}
		setIsModalOpen(!isModalOpen);
	};

	const isCopySuccess = queryClient.getQueryData(SUCCESS_MESSAGE_DATA);

	const { isLoading, isSuccess, data } = useGetProfile();

	if (isLoading) {
		<Box
			sx={{
				background: gradient.prospectiveClientLandingGradient,
				alignItems: 'center'
			}}
		>
			<Grid
				container
				item
				xs={12}
				sx={{ justifyContent: 'center', marginBottom: 3 }}
			>
				<Typography
					variant="h1Gascogne"
					sx={{
						display: 'block',
						color: primary.indigo,
						marginBottom: 2
					}}
				>
					Liability Analyzer for Prospective Clients
				</Typography>

				<Grid container item xs={12} sx={{ justifyContent: 'center' }}>
					<Box sx={{ width: 850, wordWrap: 'break-word' }}>
						<Typography variant="body1">
							With Sora&apos;s Prospective Client Debt Analyzer,
							you can qualify and convert more clients to grow
							your book. Copy a link to put in your email
							signature, send an email or download a QR code to
							put in your next presentation - the options are
							limitless. Unlimited client onboards are included
							with your subscription to Sora.
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Grid
				container
				item
				xs={12}
				sx={{ justifyContent: 'center', gap: 3, marginBottom: 8 }}
			>
				{isLoading && <Loader />}
			</Grid>

			<Grid container item xs={12} sx={{ justifyContent: 'center' }}>
				<Box
					component="img"
					src="https://prod-public-asset.s3.amazonaws.com/engineering_assets/miniapp_related/miniAppScreenShot.jpg"
					alt="Sora_Prospective_Client_Debt_Analyzer"
				/>
			</Grid>
		</Box>;
	}

	if (isSuccess) {
		const { hash } = data;

		const advisorUrl = constructMiniAppLink('verify/advisor', hash);

		const isSendEmailMode = modalVersion === 1;

		return (
			<Box
				sx={{
					background: gradient.prospectiveClientLandingGradient,
					alignItems: 'center'
				}}
			>
				{isCopySuccess && (
					<Alert key={causeRefresh} variant="success" />
				)}

				<Grid
					container
					item
					xs={12}
					sx={{ justifyContent: 'center', marginBottom: 3 }}
				>
					<Typography
						variant="h1Gascogne"
						sx={{
							display: 'block',
							color: primary.indigo,
							marginBottom: 2
						}}
					>
						Liability Analyzer for Prospective Clients
					</Typography>

					<Grid
						container
						item
						xs={12}
						sx={{ justifyContent: 'center' }}
					>
						<Box
							sx={{
								maxWidth: 850,
								wordWrap: 'break-word',
								paddingLeft: 2,
								paddingRight: 2
							}}
						>
							<Typography variant="body1">
								With Sora&apos;s Prospective Client Debt
								Analyzer, you can qualify and convert more
								clients to grow your book. Copy a link to put in
								your email signature, send an email or download
								a QR code to put in your next presentation - the
								options are limitless. Unlimited client onboards
								are included with your subscription to Sora.
							</Typography>
						</Box>
					</Grid>
				</Grid>
				<Grid
					container
					item
					xs={12}
					sx={{ justifyContent: 'center', gap: 3, marginBottom: 8 }}
				>
					{isLoading && <Loader />}
					{isSuccess && (
						<>
							<TertiaryButton onClick={() => toggleModal(1)}>
								<Typography variant="body1">
									Send an email
								</Typography>
							</TertiaryButton>

							<TertiaryButton
								onClick={() => {
									toggleModal(2);
								}}
							>
								<Typography variant="body1">
									Download a QR code
								</Typography>
							</TertiaryButton>

							<TertiaryButton
								onClick={() => {
									copyToClipboard(advisorUrl);
									queryClient.setQueryData(
										SUCCESS_MESSAGE_DATA,
										'Copied link to clipboard.'
									);
									setCauseRefresh(causeRefresh + 1);
								}}
							>
								Copy Link
							</TertiaryButton>
						</>
					)}
				</Grid>

				<Grid container item xs={12} sx={{ justifyContent: 'center' }}>
					<Box
						component="img"
						src="https://prod-public-asset.s3.amazonaws.com/engineering_assets/miniapp_related/miniAppScreenShot.jpg"
						alt="Sora_Prospective_Client_Debt_Analyzer"
					/>
				</Grid>

				<Modal
					isOpen={isModalOpen}
					handleClose={toggleModal}
					TitleComponent={
						<Typography
							variant="h2Gascogne"
							sx={{ color: primary.indigo }}
						>
							{isSendEmailMode
								? "Here's an email template to send to your client"
								: 'Download a QR code'}
						</Typography>
					}
					subtitle={
						isSendEmailMode
							? 'Copy this text, paste it in your email client and send it to your client'
							: 'Share your personalized onboarding page with prospective clients'
					}
				>
					<ModalContent
						version={modalVersion}
						primary={primary}
						toggleModal={toggleModal}
						queryClient={queryClient}
						advisorUrl={advisorUrl}
					/>
				</Modal>
			</Box>
		);
	}
}

export default ProspectiveClientPage;
