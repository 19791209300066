import { Typography } from '@mui/material';

// Our Components
import AddSingleClientForm from 'components/Forms/clientRelated/AddSingleClientForm';
import Loader from 'components/Loader';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';
import useMutateStartOnboardingProcess from 'hooks/Onboarding/AutomaticOnboarding/useMutateStartOnboardingProcess';

// Our Constants
import { ADVISOR_MANUAL } from 'shared/constants';

function OnboardSingleClientPage() {
	const {
		isLoading: isProfileLoading,
		data: userProfileData,
		isError: userProfileIsError
	} = useGetProfile();

	const mutateStartOnboardingProcess = useMutateStartOnboardingProcess();

	if (isProfileLoading) {
		return <Loader size={60} />;
	}

	if (userProfileIsError) {
		return 'Something went wrong grabbing your profile. please log back in.';
	}

	return (
		<>
			<>
				<Typography variant="h1Gascogne" component="h1">
					Add Individual Client
				</Typography>

				<Typography variant="body2" marginTop={2}>
					Enter your client&apos;s information below and they will be
					added to your account.
				</Typography>
			</>

			<AddSingleClientForm
				userProfileData={userProfileData}
				onboardingHandler={mutateStartOnboardingProcess}
				onboardingSourceType={ADVISOR_MANUAL}
			/>
		</>
	);
}

export default OnboardSingleClientPage;
