import { useQuery } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { referralPartnerEndpoint } from 'shared/api-urls';

// Our Query Keys
import { REFERRAL_PARTNERS } from 'shared/query-keys';

function useQueryGetAllReferralPartners() {
	return useQuery(REFERRAL_PARTNERS, async () => {
		const response = await axiosInstance.get(referralPartnerEndpoint);

		const advisorPartners = response.data.data;

		const partnersList = Object.values(advisorPartners.partners).map(
			({ magicToken, partner }) => ({ magicToken, ...partner })
		);

		console.log(partnersList);

		return partnersList;
	});
}

export default useQueryGetAllReferralPartners;
