import { Box, Typography, Backdrop } from '@mui/material';

function PreviewFile({
	isPreviewEnabled,
	setIsPreviewEnabled,
	previewImage,
	setPreviewImage,
	hasImageToPreview
}) {
	return (
		<Backdrop
			open={isPreviewEnabled}
			onClick={() => {
				setIsPreviewEnabled(false);
				setTimeout(() => {
					setPreviewImage(null);
				}, 100);
			}}
		>
			{hasImageToPreview && (
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<Typography variant="body2" marginBottom="10px">
						File Preview
					</Typography>
					{previewImage.isImageType ? (
						// If the file selected is of type image
						// then render the component as an image
						<Box
							component="img"
							src={previewImage.imagePath}
							sx={{
								width: 'auto',
								height: 400,
								userDrag: 'none',
								WebkitUserDrag: 'none',
								overflow: 'hidden'
							}}
						/>
					) : (
						// If the file selected is NOT an image
						// Then embed it. Currently pdfs can be embeded.
						<Box
							component="embed"
							src={previewImage.imagePath}
							frameBorder="0"
							sx={{
								width: '70vw',
								height: '70vh'
							}}
						/>
					)}
				</Box>
			)}
		</Backdrop>
	);
}

export default PreviewFile;
