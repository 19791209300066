import { useState, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Grid, Typography, Link as ExternalLink } from '@mui/material';
import { isEmail } from 'validator';

// Our Components
import Checkbox from 'components/Checkbox/Checkbox';
import ErrorAlert from 'components/Alert';
import GoogleAuthButton from 'components/Button/ThirdParty/GoogleAuthButton';
import Input from 'components/Input/TextInput';
import { PrimaryButton } from 'components/Button/Button';

// Input Types
import { EMAIL, TEXT } from 'components/Input/Types';

// Hooks
import useMutateAddUser from 'hooks/auth/useMutateAddUser';
import usePrepopulateState from 'hooks/prepopulate/usePrepopulateState';

// Routes
import { ROUTING_USER_ROUTE } from 'routes/index';

// Our Utils
import { isSubmissionReady, dataIsValid } from 'shared/utils';

// Constants
import {
	AUTH_METHOD_EMAIL,
	AUTH_METHOD_GOOGLE
} from 'shared/constants/auth/authConstants';

function SignupForm() {
	const SoraTheme = useTheme();
	const addUser = useMutateAddUser();
	const navigate = useNavigate();

	// State data will exist for invited clients
	const { state } = useLocation();

	// add user error
	const { error: addUserError, isLoading: isGoogleLoading } = addUser;

	// Create state for form items
	const [userEmail, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [agreeTerms, setTerms] = useState(false);
	const [agreePP, setPP] = useState(false);

	const formData = [userEmail, password];

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(formData) &&
			agreePP &&
			agreeTerms &&
			password.length >= 8 &&
			isEmail(userEmail),
		[...formData, agreePP, agreeTerms]
	);

	const isSignUpWithGoogleReady = useMemo(
		() => agreePP && agreeTerms,
		[agreePP, agreeTerms]
	);

	// Prepopulate email for invited clients
	usePrepopulateState(
		[{ setState: setEmail, value: state?.invitedEmail }],
		[state]
	);

	const isInviteeEmail = useMemo(
		() => dataIsValid(state?.invitedEmail),
		[state?.invitedEmail]
	);

	// Handle field changes
	const handleEmailChange = (value) => {
		setEmail(value);
	};

	const handlePasswordChange = (value) => {
		setPassword(value);
	};

	const handleTermsChange = (event) => {
		const checked = event;
		setTerms(checked);
	};

	const handlePPChange = (event) => {
		const checked = event;
		setPP(checked);
	};

	// Handle signup form submit,
	// create account w/ email and password via firebase
	const signUpWithEmail = (event) => {
		event.preventDefault();
		// mutations should invoked by their mutate fn
		addUser.mutate(
			{ email: userEmail, password, method: AUTH_METHOD_EMAIL },
			{
				onSuccess: () => {
					// This routeStateData is only used for clients

					const routeStateData = isInviteeEmail
						? {
								inviteCode: state?.inviteCode,
								onboardingSourceId: state?.onboardingSourceId,
								role: state?.role
						  }
						: {};

					navigate(ROUTING_USER_ROUTE, {
						state: routeStateData
					});
				}
			}
		);
	};

	// Handle google signup,
	// create account w/ an existing google account
	const signUpWithGoogle = (event) => {
		event.preventDefault();
		// mutations should invoked by their mutate fn
		addUser.mutate(
			{ method: AUTH_METHOD_GOOGLE },
			{
				onSuccess: () => {
					// This routeStateData is only used for clients

					const routeStateData = isInviteeEmail
						? {
								inviteCode: state?.inviteCode,
								onboardingSourceId: state?.onboardingSourceId,
								role: state?.role
						  }
						: {};

					navigate(ROUTING_USER_ROUTE, {
						state: routeStateData
					});
				}
			}
		);
	};

	return (
		<>
			{addUserError && <ErrorAlert variant="error" />}

			<Grid container rowSpacing={2}>
				<Grid item xs={9} md={10}>
					<Typography
						variant="h1Gascogne"
						component="h1"
						gutterBottom
					>
						Create your account
					</Typography>

					{/* Link to login page */}
					<Typography variant="subtitle2">
						Already have an account?&nbsp;
						<Link
							to="/login"
							style={{
								color: SoraTheme.palette.primary.black,
								textDecorationColor:
									SoraTheme.palette.primary.black
							}}
						>
							Login
						</Link>
					</Typography>
				</Grid>

				{/* Signup form */}
				<Grid
					item
					xs={12}
					md={8}
					component="form"
					noValidate
					autoComplete="off"
					sx={{
						flexDirection: 'column'
					}}
				>
					{/* Email */}
					<Input
						id={EMAIL}
						label="Email"
						type={EMAIL}
						name={EMAIL}
						onChange={handleEmailChange}
						value={userEmail}
						error={!isEmail(userEmail)}
						helperText="Please enter a valid email address"
					/>

					{/* Password */}
					<Input
						id="PASSWORD"
						label="Password"
						type={TEXT}
						value={password}
						onChange={handlePasswordChange}
						error={password.length < 8}
						helperText="Password must be minimum of 8 characters"
						withVisibleToggle
						defaultVisibility={false}
						sx={{ marginTop: 4 }}
					/>
				</Grid>

				{/* Terms of use checkbox */}
				<Grid container item xs={12} md={10} rowSpacing={2}>
					<Grid item xs={12}>
						<Checkbox
							checked={agreeTerms}
							onChange={handleTermsChange}
							label={
								<Typography variant="body2">
									I agree to Sora&apos;s&nbsp;
									<ExternalLink
										href="https://www.sorafinance.com/terms"
										sx={{
											color: SoraTheme.palette.primary
												.black,
											'&:visited': {
												color: SoraTheme.palette.primary
													.black
											}
										}}
									>
										Terms of Service
									</ExternalLink>
									&nbsp;*
								</Typography>
							}
						/>
					</Grid>

					<Grid item xs={12}>
						<Checkbox
							checked={agreePP}
							onChange={handlePPChange}
							label={
								<Typography variant="body2">
									I agree to Sora&apos;s&nbsp;
									<ExternalLink
										href="https://www.sorafinance.com/privacy-policy"
										sx={{
											color: SoraTheme.palette.primary
												.black,
											'&:visited': {
												color: SoraTheme.palette.primary
													.black
											}
										}}
									>
										Privacy Policy
									</ExternalLink>
									&nbsp;* &nbsp;and&nbsp;
									<ExternalLink
										href="https://www.sorafinance.com/data-processing-agreement"
										sx={{
											color: SoraTheme.palette.primary
												.black,
											'&:visited': {
												color: SoraTheme.palette.primary
													.black
											}
										}}
									>
										Data Processing Agreement
									</ExternalLink>
									&nbsp;*
								</Typography>
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<PrimaryButton
							onClick={signUpWithEmail}
							disabled={!isFormReady}
						>
							Sign up
						</PrimaryButton>
					</Grid>
				</Grid>

				<Grid item container rowSpacing={2}>
					<Grid item xs={12}>
						<Typography variant="body2">- or -</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="body2">
							Agree to Sora&apos;s Terms of Service, Privacy
							Policy and Data Processing Agreement in order to
							sign up with Google
						</Typography>
					</Grid>

					<Grid item xs={12} md={8}>
						<GoogleAuthButton
							onClick={signUpWithGoogle}
							disabled={!isSignUpWithGoogleReady}
							isLoading={isGoogleLoading}
							text="Sign up with Google"
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default SignupForm;
