import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Our Components
import Loader from 'components/Loader';
import TextInput from 'components/Input/TextInput';
import { PrimaryButton } from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

// Input Types
import { NUMBER } from 'components/Input/Types';

// Our Hooks
import useMutateSaveDtsScore from 'hooks/dts/useMutateSaveDtsScore';
import useGetDts from 'hooks/dts/useGetDts';

// Our Utils
import { isSubmissionReady } from 'shared/utils';

// Our Prop Types
import CommonPropTypes from 'shared/prop-types';

function DebtToleranceModal({ isModalOpen, closeModal, clientData }) {
	const [dtsScore, setDtsScore] = useState('');

	const { firstName } = clientData;

	// Hooks
	const saveDtsScore = useMutateSaveDtsScore();

	const { isLoading, isSuccess, data } = useGetDts();

	const isSubmitReady = isSubmissionReady([dtsScore]);

	const { isLoading: isSavingDtsScore } = saveDtsScore;

	useEffect(() => {
		if (isSuccess) {
			const { dtsScore: prePopulateDtsScore } = data;

			setDtsScore(prePopulateDtsScore);
		}
	}, [isSuccess, data]);

	if (isLoading) {
		return (
			<Modal
				title={`Score ${firstName}'s Assessment`}
				subtitle="Enter in the client's overall score to interpret the results"
				isOpen={isModalOpen}
				handleClose={closeModal}
			>
				<Loader />
			</Modal>
		);
	}

	if (isSuccess) {
		return (
			<Modal
				title={`Score ${firstName}'s Assessment`}
				subtitle="Enter in the client's overall score to interpret the results"
				isOpen={isModalOpen}
				handleClose={closeModal}
			>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TextInput
							label="Overall Score"
							value={dtsScore}
							onChange={setDtsScore}
							type={NUMBER}
						/>
					</Grid>
					<Grid item xs={12}>
						{!isSavingDtsScore && (
							<PrimaryButton
								isDisabled={!isSubmitReady}
								onClick={() => {
									const { clientId } = clientData;
									saveDtsScore.mutate(
										{ dtsScore, clientId },
										{
											onSuccess: () => {
												closeModal();
											}
										}
									);
								}}
							>
								Submit
							</PrimaryButton>
						)}

						{isSavingDtsScore && <Loader />}
					</Grid>
				</Grid>
			</Modal>
		);
	}
}

DebtToleranceModal.propTypes = {
	isModalOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
	clientData: CommonPropTypes.clientDataPropType.isRequired
};

export default DebtToleranceModal;
