import { useState, useCallback } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';

// Our Assets
import DebtTolerancePDF from 'assets/pdfs/Debt_Tolerance_Assessment.pdf';

// Our Components
import DebtToleranceModal from 'components/Debt-Tolerance/DebtToleranceModal';

// Our Prop Types
import CommonPropTypes from 'shared/prop-types';

// Our Utils
import programaticDownload from 'shared/utils/developmentQualityOfLife/programaticDownload';

function InviteButtonClientDTA({ clientData, variant }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;
	const [isModalOpen, setIsModalOpen] = useState(false);

	const closeModal = useCallback(() => {
		setIsModalOpen(false);
	}, []);

	const isModal = variant === 'modal';

	return (
		<>
			<Typography
				variant="body2"
				component="span"
				sx={{
					cursor: 'pointer',
					color: primary.black,
					marginLeft: 0.5,
					marginRight: 0.5,
					textDecoration: 'underline 1px solid black',
					textUnderlineOffset: 2,
					fontWeight: 700,
					display: 'inline'
				}}
				onClick={() => {
					if (isModal) {
						setIsModalOpen(true);
					} else {
						try {
							programaticDownload(
								DebtTolerancePDF,
								'Debt_Tolerance_Assessment.pdf',
								'application/pdf',
								true
							);
						} catch (e) {
							console.error(e);
						}
					}
				}}
			>
				here
			</Typography>

			{isModal && (
				<DebtToleranceModal
					isModalOpen={isModalOpen}
					closeModal={closeModal}
					clientData={clientData}
				/>
			)}
		</>
	);
}

InviteButtonClientDTA.propTypes = {
	clientData: CommonPropTypes.clientDataPropType.isRequired,
	variant: PropTypes.string
};

InviteButtonClientDTA.defaultProps = {
	variant: 'link'
};

export default InviteButtonClientDTA;
