import { Box, Typography } from '@mui/material';

function FilesList({
	files,
	filesRejected,
	acceptedFilesItems,
	rejectedFileItems
}) {
	return (
		<Box
			sx={{
				paddingTop: 2,
				paddingBottom: 2,
				marginTop: 2,
				marginBottom: 2,
				display: 'flex',
				flexDirection: 'column',
				height: 'auto'
			}}
		>
			<Typography variant="subtitle1">Files</Typography>
			{files.length < 1 && filesRejected.length < 1 ? (
				<Typography>Upload files to get started</Typography>
			) : (
				acceptedFilesItems
			)}

			{filesRejected.length > 0 && rejectedFileItems}
		</Box>
	);
}

export default FilesList;
