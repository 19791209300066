import { Grid } from '@mui/material';
import { useState } from 'react';

// Our Components
import EmailInput from 'components/Input/EmailInput';
import PageHeading from 'components/PageHeading/PageHeading';
import Loader from 'components/Loader/index';
import Modal from 'components/Modal/Modal';
import { TertiaryButton } from 'components/Button/Button';
import TextInput from 'components/Input/TextInput';
import ReferralPartnerTable from 'components/DataTable/ReferralPartnerTable/ReferralPartnerTable';

// Input Types
import { TEXT } from 'components/Input/Types';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';
import useQueryGetAllReferralPartners from 'hooks/referralPartners/useQueryGetAllReferralPartners';
import useMutateCreateReferralPartner from 'hooks/referralPartners/useMutateCreateReferralPartner';

// Our Utils
import {
	clearFormValues,
	isSubmissionReady,
	simpleValidEmail
} from 'shared/utils';

function AddReferralPartnerPage() {
	// Modal State
	const [isModalOpen, setIsModalOpen] = useState(false);

	// Form State
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');

	// Form Related
	const requiredFormValues = [firstName, lastName, email];

	const isEmailValid = simpleValidEmail(email);

	const isReferralFormReady =
		isSubmissionReady(requiredFormValues) && isEmailValid;

	const toggleModal = () => {
		if (isModalOpen) {
			clearFormValues([setFirstName, setLastName, setEmail]);
		}
		setIsModalOpen(!isModalOpen);
	};

	const { isSuccess, data: userProfileData } = useGetProfile();

	const {
		isLoading: isGetReferralPartnersLoading,
		isSuccess: isGetReferralPartnersSuccess,
		data: referralPartners
	} = useQueryGetAllReferralPartners();

	const addReferralPartner = useMutateCreateReferralPartner();

	const { isLoading: isAddReferralPartnerLoading } = addReferralPartner;

	if (isGetReferralPartnersLoading) return <Loader />;

	if (isSuccess && isGetReferralPartnersSuccess) {
		return (
			<>
				<PageHeading
					title="Add Referral Partners"
					subtitleText="Got someone adding clients on your behalf? Add them here to generate a trackable sign-up link for them to send to clients."
				/>

				<ReferralPartnerTable referralPartners={referralPartners} />
				<TertiaryButton onClick={() => toggleModal()}>
					Add Referral Partner
				</TertiaryButton>

				<Modal
					isOpen={isModalOpen}
					handleClose={toggleModal}
					title="Add Referral Partner"
					subtitle="Enter your referral partners details below"
				>
					<Grid
						component="form"
						container
						rowSpacing={2}
						columnSpacing={3}
					>
						<Grid item xs={6}>
							<TextInput
								label="First Name"
								value={firstName}
								onChange={setFirstName}
								type={TEXT}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextInput
								label="Last Name"
								value={lastName}
								onChange={setLastName}
								type={TEXT}
							/>
						</Grid>{' '}
						<Grid item xs={6}>
							<EmailInput
								label="Email"
								email={email}
								handleEmailChange={setEmail}
							/>
						</Grid>
						<Grid item xs={12}>
							{isAddReferralPartnerLoading && <Loader />}
							{!isAddReferralPartnerLoading && (
								<TertiaryButton
									type="submit"
									isDisabled={!isReferralFormReady}
									onClick={() => {
										const addReferralPartnerPayload = {
											referralPartner: {
												firstname: firstName,
												lastname: lastName,
												email
											},
											advisorId: userProfileData.id
										};

										addReferralPartner.mutate(
											addReferralPartnerPayload
										);

										toggleModal();
									}}
								>
									Save
								</TertiaryButton>
							)}
						</Grid>
					</Grid>
				</Modal>
			</>
		);
	}
}

export default AddReferralPartnerPage;
