import { Typography } from '@mui/material';
// This Component is for the Columns for Data-Grid.
// The columns are simple objects instead of React components that Data-Grid will manipulate
// This fn is simply to use Sora's default arguments making it easier to work with.
// Resource - https://mui.com/x/react-data-grid/
// Grid dimensions - https://mui.com/x/react-data-grid/column-dimensions/#api

export default (field, headerName, flex = 0, props = {}) => ({
	field,
	headerName,
	flex,
	minWidth: 150,
	disableColumnMenu: true,
	editable: false,
	renderHeader: (
		{ colDef } // this fn will determine how the column header is rendered. colDef are the properties specified here(e.g. field, headerName) and any props passed in.
	) => <Typography variant="overline">{colDef.headerName}</Typography>,
	...props
});
