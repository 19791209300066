import { Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';

// Our Components
import Alert from 'components/Alert';
import SearchClients from 'components/FADashboard/SearchClients';
import RefreshButton from 'components/Button/RefreshButton';

// Our Hooks
import useMutateRefreshAllClientData from 'hooks/dataRefresh/useMutateRefreshAllClientData';

function TableHeadingAndSearch({ disable, tableHeading, deBounceCallBack }) {
	const SoraTheme = useTheme();

	const refreshAllClientData = useMutateRefreshAllClientData();

	const { isLoading, isSuccess, isError } = refreshAllClientData;

	return (
		<>
			{isSuccess && <Alert variant="success" />}
			{isError && <Alert variant="error" />}

			<Grid
				container
				item
				xs={12}
				sx={{
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: 4
				}}
			>
				<Grid
					item
					xs={12}
					sm={6}
					sx={{
						display: 'flex',
						gap: 2,
						alignItems: 'center'
					}}
				>
					<Typography
						variant="h2Gascogne"
						component="h2"
						sx={{
							color: SoraTheme.palette.primary.indigo
						}}
					>
						{tableHeading}
					</Typography>
					<RefreshButton
						disable={disable}
						isLoading={isLoading}
						handleOnClick={() => refreshAllClientData.mutate({})}
					/>
				</Grid>

				<Grid item xs={12} sm={4} xl={3} sx={{ marginRight: 4 }}>
					<SearchClients debounceCallBack={deBounceCallBack} />
				</Grid>
			</Grid>
		</>
	);
}

TableHeadingAndSearch.propTypes = {
	tableHeading: PropTypes.string.isRequired
};

export default TableHeadingAndSearch;
