import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

// Our Components
import Alert from 'components/Alert/index';
import RefreshButton from 'components/Button/RefreshButton';
import TasksAccordion from 'components/Tasks/TasksAccordion';
import TasksModal from 'components/Tasks/OnboardingTasksModal';
import WealthboxOnboardingTable from 'components/Tasks/WealthBoxOnboarding/WealthboxOnboardingTable';

// Our Hooks
import useMutateAddClientInvitation from 'hooks/useMutateAddClientInvitation';
import useMutateReinviteClient from 'hooks/tasks/useMutateReinviteClient';
import useMutateStartOnboardingProcess from 'hooks/Onboarding/AutomaticOnboarding/useMutateStartOnboardingProcess';
import useMutateRefreshWealthboxClientList from 'hooks/wealthbox/refresh/useMutateRefreshWealthboxClientList';

// Our Query keys
import { WEALTH_BOX_CLIENTS, WEALTH_BOX_DONE_CLIENTS } from 'shared/query-keys';

// Our Consants
import { DEFAULT_TASK_ACTION_OPTIONS } from 'shared/constants';

const [COMPLETE_ONBOARD] = DEFAULT_TASK_ACTION_OPTIONS;

function OnboardingTasks() {
	// Mutations
	const sendClientInvitation = useMutateAddClientInvitation();
	const sendClientReInvitation = useMutateReinviteClient();
	const startOnboardingProcess = useMutateStartOnboardingProcess();
	const refreshWealthboxClientList = useMutateRefreshWealthboxClientList();

	// State
	const [modalMode, setModalMode] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [clientSelected, setClientSelected] = useState(null);

	const handleSelected = (value, clientData, pageCallBackNumber) => {
		setModalMode(value);
		setIsModalOpen(true);
		setClientSelected({ ...clientData, pageCallBackNumber });
	};

	useEffect(() => {
		if (!isModalOpen && clientSelected !== null) setClientSelected(null);
	}, [clientSelected, isModalOpen]);

	const isSuccessAlert =
		sendClientInvitation.isSuccess || sendClientReInvitation.isSuccess;

	const isErrorAlert =
		sendClientInvitation.isError || sendClientReInvitation.isError;

	return (
		<>
			{isSuccessAlert && <Alert variant="success" />}
			{isErrorAlert && <Alert variant="error" />}

			<Grid item xs={12}>
				<RefreshButton
					isLoading={refreshWealthboxClientList.isLoading}
					handleOnClick={() => refreshWealthboxClientList.mutate({})}
				/>
			</Grid>

			<Grid item xs={12}>
				<TasksAccordion defaultExpandState taskHeading="Onboarding">
					<WealthboxOnboardingTable
						handleSelected={handleSelected}
						KEY={WEALTH_BOX_CLIENTS}
					/>
				</TasksAccordion>
			</Grid>

			<Grid item xs={12}>
				<TasksAccordion taskHeading="Done">
					<WealthboxOnboardingTable
						disableOverlay
						handleSelected={handleSelected}
						KEY={WEALTH_BOX_DONE_CLIENTS}
						optionalParams={{ done: true }}
					/>
				</TasksAccordion>
			</Grid>

			{modalMode !== '' && clientSelected && (
				<TasksModal
					clientSelected={clientSelected}
					isInvite={modalMode !== COMPLETE_ONBOARD}
					isOpen={isModalOpen}
					setIsOpen={setIsModalOpen}
					setModalMode={setModalMode}
					sendClientInvitation={sendClientInvitation}
					sendClientReInvitation={sendClientReInvitation}
					startOnboardingProcess={startOnboardingProcess}
				/>
			)}
		</>
	);
}

export default OnboardingTasks;
