import mimeTypes from 'shared/mimeTypes.json';

const getFileExtension = (filename) =>
	filename.slice(filename.lastIndexOf('.'));

function programaticDownload(fileContent, fileName, mimeType, isUrl = false) {
	if (isUrl) {
		// anchor tag is created
		const link = document.createElement('a');

		// the download path is appended via href
		link.href = fileContent;

		// the name of the file is provided in the API thus .download == fileName
		link.download = fileName;

		// We create a temporary anchor tag in the dom
		document.body.appendChild(link);

		// programatically click it
		link.click();

		// Now that the file is downloaded we no longer need this temp anchor.
		document.body.removeChild(link);
		return;
	}

	// This grabed the file extension string from the fileName
	// e.g. foo.png -> .png is returned
	const fileExt = getFileExtension(fileName);

	// if mimeType is provided to this function then the provided mimeType will be used
	// otherwise we have a known list of mime types and the file extension above is provided
	// e.g. .png -> mimeTypes.json -> image/png is returned
	const determinedMineType = mimeType ?? mimeTypes[fileExt];

	// Here we construct a download url
	// We do so by telling the browser to look at data:mimeType;base64,data
	// data means data. mimeType means the file of file e.g. image/png. ; base64 represents the encoding, data is the base64 encoded image or data
	// Here I guard the downloadURl by having a fallback of application/octet-stream, that way the download ALWAYS works.
	const downloadUrl = `data:${
		determinedMineType ?? 'application/octet-stream'
	};base64,${fileContent}`;

	// Now that we got the file/image/base crap out of the way we can now confidently download this file

	// anchor tag is created
	const link = document.createElement('a');

	// the download path is appended via href
	link.href = downloadUrl;

	// the name of the file is provided in the API thus .download == fileName
	link.download = fileName;

	// We create a temporary anchor tag in the dom
	document.body.appendChild(link);

	// programatically click it
	link.click();

	// Now that the file is downloaded we no longer need this temp anchor.
	document.body.removeChild(link);
}

export default programaticDownload;
