import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// Our Components
import Loader from 'components/Loader/index';

// Our Hooks
import useQueryVerifyClientInvite from 'hooks/clientInvite/useQueryVerifyClientInvite';

// Our Constants
import { CLIENT } from 'shared/constants';

function VerifyClientInvite() {
	const navigate = useNavigate();

	const {
		error,
		data: acceptedInviteData,
		isSuccess
	} = useQueryVerifyClientInvite();

	if (error) return `An error has occurred: ${error.message}`;

	if (isSuccess) {
		navigate('/signup', {
			state: {
				inviteCode: acceptedInviteData.invitedCode,
				invitedEmail: acceptedInviteData.invitedEmail,
				onboardingSourceId: acceptedInviteData.inviteeId,
				role: CLIENT
			}
		});
	}

	return (
		<>
			<Helmet>
				<title>Verify Client Invite Page</title>
			</Helmet>
			<Loader size={60} boxSX={{ justifyContent: 'center' }} />
		</>
	);
}

export default VerifyClientInvite;
