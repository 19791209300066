import { Grid, Skeleton, Typography } from '@mui/material';

// Our Components
import UploadFilesBox from 'components/FileUpload/UploadFile/UploadFilesBox';

// Our Hooks
import useQueryGetUserBoxFolder from 'hooks/integrations/box/useQueryGetUserBoxFolder';

function AdvisorSecureMessaging() {
	const {
		data: userBoxFolder,
		isLoading,
		isSuccess
	} = useQueryGetUserBoxFolder();

	if (isLoading) {
		return <Skeleton variant="rectangular" height={100} />;
	}

	if (isSuccess) {
		const { supportFolder: supportFolderId } = userBoxFolder;

		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography
						variant="body1"
						sx={{ display: 'block', marginBottom: 2 }}
					>
						For secured messaging or data sharing, upload documents
						to the below with your name clearly marked in the
						uploaded file.
					</Typography>
					<Grid item xs={6}>
						<UploadFilesBox folderId={supportFolderId} />
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default AdvisorSecureMessaging;
