import { useQueryClient, useMutation } from 'react-query';
import {
	getAuth,
	signInWithEmailAndPassword,
	GoogleAuthProvider,
	signInWithPopup
} from 'firebase/auth';

// Our Hooks
import useMutateCommonAuth from 'hooks/auth/useMutateCommonAuth';

// Our Query Keys
import { ERROR_MESSAGE_DATA, FIREBASEUSERCREDENTIALS } from 'shared/query-keys';

// Constants
import {
	AUTH_METHOD_EMAIL,
	AUTH_METHOD_GOOGLE
} from 'shared/constants/auth/authConstants';

const useMutateLoginUser = () => {
	const queryClient = useQueryClient();
	const auth = getAuth();
	const provider = new GoogleAuthProvider();
	provider.setCustomParameters({
		prompt: 'select_account'
	});

	const commonAuth = useMutateCommonAuth();

	return useMutation(
		async ({ email, password, method }) => {
			await commonAuth.mutate({ email, password, method });

			let userCredential;

			if (method === AUTH_METHOD_EMAIL) {
				userCredential = await signInWithEmailAndPassword(
					auth,
					email,
					password
				);
			} else if (method === AUTH_METHOD_GOOGLE) {
				userCredential = await signInWithPopup(auth, provider);
			}

			return userCredential.user.accessToken;
		},
		{
			onSuccess: (data) => {
				queryClient.setQueryData(FIREBASEUSERCREDENTIALS, data);
			},
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error.message);
			}
		}
	);
};

export default useMutateLoginUser;
