// @ts-nocheck
import { Typography, Grid } from '@mui/material';

// Icons
import NumberOne from '@mui/icons-material/LooksOne';
import NumberTwo from '@mui/icons-material/LooksTwo';
import NumberThree from '@mui/icons-material/Looks3';

// Our Components
import UploadFilesBox from 'components/FileUpload/UploadFile/UploadFilesBox';
import { TertiaryButton } from 'components/Button/Button';
import Loader from 'components/Loader';

// Our Hooks
import useQueryGetUserBoxFolder from 'hooks/integrations/box/useQueryGetUserBoxFolder';

function AddClientsBySpreadsheet() {
	const {
		data: userBoxFolder,
		isLoading,
		isSuccess
	} = useQueryGetUserBoxFolder();

	if (isLoading) {
		return <Loader />;
	}

	if (isSuccess) {
		const { onboardingsFolder: onboardingsFolderId } = userBoxFolder;

		return (
			<Grid container rowSpacing={6}>
				<Grid item xs={12}>
					<Typography variant="h1Gascogne" component="h1">
						Upload Multiple Clients
					</Typography>
				</Grid>
				<Grid item xs={1}>
					<NumberOne
						sx={{
							fontSize: 40,
							color: 'primary.lightGrey',
							marginLeft: 4
						}}
					/>
				</Grid>
				<Grid item xs={11}>
					<Typography
						variant="body1"
						sx={{ display: 'block', marginBottom: 2 }}
					>
						Download the spreadsheet and fill it out with your
						client&apos;s details.
					</Typography>
					{/* Download spreadsheet link here */}
					<a
						href="https://prod-public-asset.s3.amazonaws.com/Sora-Automated-Onboarding-Service.xlsx"
						download
						style={{ textDecoration: 'none' }}
					>
						<TertiaryButton>
							Download onboarding spreadsheet
						</TertiaryButton>
					</a>
				</Grid>

				<Grid item xs={1}>
					<NumberTwo
						sx={{
							fontSize: 40,
							color: 'primary.lightGrey',
							marginLeft: 4
						}}
					/>
				</Grid>
				<Grid item xs={11}>
					<Typography variant="body1">
						Add your client&apos;s details to the spreadsheet.
					</Typography>
				</Grid>

				<Grid item xs={1}>
					<NumberThree
						sx={{
							fontSize: 40,
							color: 'primary.lightGrey',
							marginLeft: 4
						}}
					/>
				</Grid>
				<Grid container item xs={11}>
					<Grid item xs={12}>
						<Typography variant="body1">
							Securely upload your completed spreadsheet and your
							clients will be added to your account within the
							next 24 hours.
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<UploadFilesBox folderId={onboardingsFolderId} />
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default AddClientsBySpreadsheet;
