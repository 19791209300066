import { useMutation } from 'react-query';

// Our Services
import BoxAPI from 'services/API/BoxAPI';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

function useMutateUploadBoxFile(folderId) {
	const { data: profile } = useGetProfile();

	const { id, firstName, lastName } = profile;

	return useMutation(async (file) => {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('folderId', folderId);
		formData.append('userId', id);
		formData.append('advisorFirstName', firstName);
		formData.append('advisorLastName', lastName);

		const response = await BoxAPI.post('create/file', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
		return response.data;
	});
}

export default useMutateUploadBoxFile;
