import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useMemo } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import numbro from 'numbro';

// Our Components
import FileTypeIcon from 'components/FileUpload/FileTypeIcon';
import Loader from 'components/Loader';
import { TextButton } from 'components/Button/Button';
import PreviewButton from 'components/FileUpload/PreviewButton';

// Our Hooks
import useQueryUploadFile from 'hooks/clientOnboarding/useQueryUploadFile';

// This function grabs the fileName and shortens it to 15 characters excluding the filetype
const getFileExtension = (file) => {
	try {
		const fileType = file.type;
		const slashIndex = file.type.indexOf('/') + 1;
		return fileType.slice(slashIndex);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e);
		return 'unknown';
	}
};

function FileItem({
	file,
	setFileToPreview,
	removeFile,
	error,
	isRejectedFile,
	fileId,
	isUploading,
	handleUploadFile
}) {
	const SoraTheme = useTheme();

	const { error: errorColor } = SoraTheme.palette;

	const fileSize = file.size;
	const fileName = file.name;
	const fileExt = useMemo(() => getFileExtension(file), [file]);
	const filePath = useMemo(() => window.URL.createObjectURL(file), [file]); // this filePath is a usuable one. i.e you can pass this var to an img or embed html tag as its src
	const isImageType = useMemo(() => file.type.includes('image'), [file]);
	const isEmeddableFile = isImageType || fileExt.includes('pdf'); // can the file be previewed? If its embeddable its previewable.

	const FILE_SIZE = useMemo(
		() =>
			fileSize &&
			numbro(fileSize)
				.format({
					output: 'byte',
					base: 'binary',
					mantissa: 2
				})
				.replace('i', '')
				.toUpperCase(),
		[file]
	);

	const ERROR_MESSAGE =
		error && error.code === 'file-too-large'
			? 'File cannot be larger than 5MB'
			: error?.message;

	const isFileUploadEnabled = !isRejectedFile && isUploading;

	const {
		isLoading,
		isSuccess,
		error: uploadError
	} = useQueryUploadFile(isFileUploadEnabled, fileId, file, handleUploadFile);

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				height: 56
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: 2
				}}
			>
				<FileTypeIcon
					isRejectedFile={isRejectedFile}
					fileType={file.type}
					fileExt={fileExt}
				/>
				<Typography
					variant="body2"
					sx={{
						width: '15ch',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap'
					}}
				>
					{fileName}
				</Typography>

				<Typography
					variant="body2"
					sx={{
						width: '5ch',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap'
					}}
				>
					{fileExt}
				</Typography>
				<TextButton
					sx={{ color: errorColor.main }}
					onClick={() => {
						removeFile(file);
					}}
				>
					Delete
				</TextButton>
				<PreviewButton
					isRejectedFile={isRejectedFile}
					isEmeddableFile={isEmeddableFile}
					setFileToPreview={() =>
						setFileToPreview({
							imagePath: filePath,
							isImageType
						})
					}
				/>
				<Box>
					{(error || uploadError) && (
						<Typography
							variant="body2"
							color={errorColor.main}
							sx={{
								'&::before': {
									content: "'Error: '",
									fontWeight: 'bold'
								}
							}}
						>
							{ERROR_MESSAGE ?? 'Something went wrong'}
						</Typography>
					)}
				</Box>
			</Box>
			{isLoading && <Loader />}
			{isSuccess && <DoneIcon color="green" />}
			<Typography variant="body2" sx={{ width: 70, textAlign: 'right' }}>
				{FILE_SIZE}
			</Typography>
		</Box>
	);
}

export default FileItem;
