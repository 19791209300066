import { Grid } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { isMobilePhone, isDate } from 'validator';

// Our components
import Alert from 'components/Alert/index';
import Loader from 'components/Loader';
import { TertiaryButton } from 'components/Button/Button';
import PhoneNumberInput from 'components/Input/PhoneNumberInput';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import TextInput from 'components/Input/TextInput';
import { TEXT } from 'components/Input/Types';

// Our hooks
import useGetProfile from 'hooks/useGetProfile';
import useMutateAddProfileData from 'hooks/useMutateUpdateProfileData';
import useMutateRenameBoxFolder from 'hooks/integrations/box/useMutateRenameBoxFolder';
import useQueryGetUserBoxFolder from 'hooks/integrations/box/useQueryGetUserBoxFolder';

// Our Query Keys
import { ERROR_MESSAGE_DATA, SUCCESS_MESSAGE_DATA } from 'shared/query-keys';

// Utils
import { isSubmissionReady, dataIsValid } from 'shared/utils';
import getNonNullValues from 'shared/utils/clientOnboarding/getNonNullValues';
import convertDateToDatePickerFormat from 'shared/utils/formatting/convertDateToDatePickerFormat';
import convertDateToEndpointFormat from 'shared/utils/formatting/convertDateToEndpointFormat';
import formatPhoneNumber from 'shared/utils/formatting/formatPhoneNumber';

// Constants
import PhoneNumberWhitelist from 'shared/phone-number-whitelist.json';
import { ADDRESS_FIELDS_TO_IGNORE } from 'shared/constants';

// helper function select userProfile related fields
const selectOnlyRequiredFields = ({
	address,
	citizenshipStatus,
	creditScore,
	dob,
	employmentStatus,
	employer,
	firstName,
	highestDegree,
	lastName,
	mfiCreditScore,
	monthlyRent,
	phoneNumber,
	veteran,
	role,
	id
}) => {
	const userProfileDataWithoutNullValues = getNonNullValues({
		address,
		citizenshipStatus,
		creditScore,
		dob,
		employmentStatus,
		employer,
		firstName,
		highestDegree,
		lastName,
		mfiCreditScore,
		monthlyRent,
		phoneNumber,
		veteran,
		role,
		id
	});

	return userProfileDataWithoutNullValues;
};

function SettingsForm() {
	const queryClient = useQueryClient();

	const {
		isLoading: isLoadingProfile,
		isSuccess: isSuccessProfile,
		data
	} = useGetProfile(selectOnlyRequiredFields);

	const {
		data: userBoxFolder,
		isLoading: isLoadingUserBoxFolder,
		isSuccess: isSuccessUserBoxFolder
	} = useQueryGetUserBoxFolder();

	const updateProfile = useMutateAddProfileData();
	const renameBoxFolder = useMutateRenameBoxFolder();

	// true for Advisors.
	const isAdvisor = useMemo(() => data?.role === 'ADVISOR', [data]);

	const {
		isLoading: isUpdateProfileLoading,
		isSuccess: isUpdateProfileSuccess,
		isError: isUpdateProfileError
	} = updateProfile;

	// Required fields
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [dob, setDOB] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');

	// Other Personal Fields
	const [citizenshipType, setCitizenshipType] = useState('US Citizen');
	const [isVeteran, setIsVeteran] = useState('');
	const [educationRating, setEducationRating] = useState('');
	const [employmentStatus, setEmploymentStatus] = useState('Employed');

	// Address fields
	const [addressLine1, setAddressLine1] = useState('');
	const [addressLine2, setAddressLine2] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [zipCode, setZipCode] = useState('');

	// Optional fields
	const [creditScore, setCreditScore] = useState('');
	const [monthlyRentPayment, setMonthlyRentPayment] = useState('');
	const [employer, setEmployer] = useState('');

	// separate
	const [disableAutoComplete, setDisableAutoComplete] = useState(false);

	const requiredFormData = [
		firstName,
		lastName,
		addressLine1,
		city,
		state,
		zipCode,
		phoneNumber
	];

	const isValidDate = useMemo(() => {
		const isStringDate = typeof dob === 'string';
		const isDateType = dob instanceof Date;
		if (isStringDate && dob !== '') {
			const isDateCheckWithConversion = isDate(new Date(dob));
			return isDateCheckWithConversion;
		}

		if (isDateType) {
			return isDate(dob);
		}
		// if dob is null this returns false;
		return false;
	}, [dob]);

	const isValidPhoneNumber = useMemo(() => {
		if (PhoneNumberWhitelist.includes(phoneNumber)) {
			return true;
		}
		return isMobilePhone(phoneNumber, 'en-US');
	}, [phoneNumber]);

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(requiredFormData) &&
			phoneNumber !== '' &&
			isValidPhoneNumber &&
			isValidDate,
		[...requiredFormData, phoneNumber, dob]
	);

	useEffect(() => {
		if (isSuccessProfile) {
			setDisableAutoComplete(true);
			const WANTED_KEY_SET_MAPPING = {
				addressLine1: setAddressLine1,
				addressLine2: setAddressLine2,
				city: setCity,
				citizenshipStatus: setCitizenshipType,
				dob: setDOB,
				employmentStatus: setEmploymentStatus,
				employer: setEmployer,
				firstName: setFirstName,
				highestDegree: setEducationRating,
				lastName: setLastName,
				monthlyRent: setMonthlyRentPayment,
				phoneNumber: setPhoneNumber,
				state: setState,
				veteran: setIsVeteran,
				zipcode: setZipCode
			};

			const userProfileFields = Object.keys(data);

			try {
				// manual pre-populate
				const hasCreditScore = dataIsValid(data?.creditScore);
				const hasMfiCreditScore = dataIsValid(data?.mfiCreditScore);

				const currentCreditScore = data?.creditScore;
				const currentMfiCreditScore = data?.mfiCreditScore;
				if (hasCreditScore && hasMfiCreditScore) {
					// take any nonZero Value
					if (currentCreditScore > 0) {
						setCreditScore(currentCreditScore);
					} else {
						setCreditScore(currentMfiCreditScore);
					}
				} else if (hasCreditScore) {
					// if we hit this block hasMfiCreditScore is false
					setCreditScore(currentCreditScore);
				} else {
					// if we hit this block hasCreditScore is false
					setCreditScore(currentMfiCreditScore);
				}

				// dynamic pre-populate
				userProfileFields.forEach((userProfileField) => {
					// skip these fields right off the bat.
					if (
						userProfileField === 'role' ||
						userProfileField === 'creditScore' ||
						userProfileField === 'mfiCreditScore' ||
						userProfileField === 'id'
					)
						return;
					const currentData = data[userProfileField];
					const setUpdater = WANTED_KEY_SET_MAPPING[userProfileField];

					if (userProfileField === 'address') {
						const addressRelatedFieldsWithoutNullValues =
							getNonNullValues(currentData);

						const addressRelatedFields = Object.keys(
							addressRelatedFieldsWithoutNullValues
						);

						const requiredAddressRelatedFields =
							addressRelatedFields.filter(
								(fieldName) =>
									!ADDRESS_FIELDS_TO_IGNORE.has(fieldName)
							);

						requiredAddressRelatedFields.forEach(
							(addressRelatedField) => {
								const currentAddressRelatedData =
									currentData[addressRelatedField];
								const addressRelatedUpdater =
									WANTED_KEY_SET_MAPPING[addressRelatedField];
								addressRelatedUpdater(
									currentAddressRelatedData
								);
							}
						);
						return;
					}

					if (userProfileField === 'phoneNumber') {
						if (PhoneNumberWhitelist.includes(currentData)) {
							setUpdater(currentData);
							return;
						}

						const formattedPhoneNumber =
							formatPhoneNumber(currentData);
						setUpdater(formattedPhoneNumber);
						return;
					}
					if (userProfileField === 'monthlyRent') {
						setUpdater(`${currentData}`);
						return;
					}
					if (userProfileField === 'veteran') {
						if (currentData) {
							setUpdater('Yes');
						} else {
							setUpdater('No');
						}
						return;
					}

					if (userProfileField === 'dob') {
						const convertedDate =
							convertDateToDatePickerFormat(currentData);

						setUpdater(convertedDate);
						return;
					}

					// Advisors have a mfiCreditScore in their profile.
					// However they currently never go through MFI and thus their mfiCreditScore value is always 0
					// This results in a bug where mfiCreditScore overwrites creditScore saved for Advisors
					// BE confirmed mfiCreditScore takes precendence over creditScore and thus for clients this overwrite is intended.
					if (userProfileField === 'mfiCreditScore') {
						if (isAdvisor) return;
					}

					setUpdater(currentData);
				});
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}
	}, [data]);

	const handleUpdateProfileClick = () => {
		const filteredPhoneNumber = phoneNumber?.replaceAll('-', '');
		const formattedCreditScore = Number.parseInt(creditScore, 10);
		const formattedRent = Number.parseInt(monthlyRentPayment, 10);
		const formattedIsVeteran = isVeteran === 'Yes';
		const dobChanged = typeof dob !== 'string';

		const userDataForProfile = {
			addressLine1,
			addressLine2,
			city,
			citizenshipStatus: citizenshipType,
			creditScore: formattedCreditScore,
			highestDegree: educationRating,
			employer,
			employmentStatus,
			firstName,
			isVeteran: formattedIsVeteran,
			lastName,
			monthlyRent: formattedRent,
			phoneNumber: filteredPhoneNumber,
			state,
			zipcode: zipCode
		};

		if (dobChanged) {
			const formattedDob = convertDateToEndpointFormat(dob);
			userDataForProfile.dob = formattedDob;
		}

		const { advisorFolder } = userBoxFolder;

		updateProfile.mutate(userDataForProfile, {
			onSuccess: async () => {
				queryClient.setQueryData(
					SUCCESS_MESSAGE_DATA,
					'Your profile was updated!'
				);
				if (isAdvisor) {
					await renameBoxFolder.mutateAsync({
						userId: data?.id,
						folderId: advisorFolder,
						advisorFirstName: firstName,
						advisorLastName: lastName
					});
				}
			},
			onError: (e) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, e.message);
			}
		});
	};

	const isLoading = isLoadingProfile || isLoadingUserBoxFolder;
	const shouldCheckBoxStatus = isAdvisor ? isSuccessUserBoxFolder : true;

	const isSuccess = isSuccessProfile && shouldCheckBoxStatus;

	if (isLoading) return <Loader size={50} />;

	if (isSuccess) {
		return (
			<Grid container item xs={12} rowSpacing={3}>
				{isUpdateProfileSuccess && <Alert variant="success" />}
				{isUpdateProfileError && <Alert variant="error" />}
				{isAdvisor && (
					<Grid
						container
						item
						xs={12}
						rowSpacing={3}
						columnSpacing={2}
					>
						<Grid item xs={12} md={3}>
							<ProfileAvatar />
						</Grid>
						<Grid item xs={12} md={3}>
							<ProfileAvatar isCorporate />
						</Grid>
					</Grid>
				)}

				<Grid
					component="form"
					container
					item
					noValidate
					columnSpacing={2}
					rowSpacing={3}
					autoComplete="off"
				>
					<Grid item xs={5}>
						<TextInput
							label="First name"
							value={firstName}
							onChange={setFirstName}
							type={TEXT}
						/>
					</Grid>

					<Grid item xs={5}>
						<TextInput
							label="Last name"
							value={lastName}
							onChange={setLastName}
							type={TEXT}
						/>
					</Grid>

					<Grid item xs={5}>
						<PhoneNumberInput
							phoneNumber={phoneNumber}
							onChange={setPhoneNumber}
							isValidPhoneNumber={isValidPhoneNumber}
							subLabel=""
						/>
					</Grid>

					<GetFullAddressPartialForm
						addressLine1={addressLine1}
						addressLine2={addressLine2}
						city={city}
						state={state}
						zipCode={zipCode}
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
						defaultMarginBottom={0}
						defaultColumnWidth={5}
						defaultAddressColumnWidth={10}
					/>
				</Grid>

				<Grid item xs={12}>
					{isUpdateProfileLoading && (
						<Loader boxSX={{ height: 32 }} />
					)}
					{!isUpdateProfileLoading && (
						<TertiaryButton
							disabled={!isFormReady}
							onClick={handleUpdateProfileClick}
						>
							Save
						</TertiaryButton>
					)}
				</Grid>
			</Grid>
		);
	}
}

export default SettingsForm;
