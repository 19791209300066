import { useQueryClient, useMutation } from 'react-query';
import {
	createUserWithEmailAndPassword,
	getAuth,
	GoogleAuthProvider,
	signInWithPopup
} from 'firebase/auth';

// Our Hooks
import useMutateCommonAuth from 'hooks/auth/useMutateCommonAuth';

// Query Keys
import { ERROR_MESSAGE_DATA, FIREBASEUSERCREDENTIALS } from 'shared/query-keys';

// Constants
import {
	AUTH_METHOD_EMAIL,
	AUTH_METHOD_GOOGLE
} from 'shared/constants/auth/authConstants';

const useMutateAddUser = () => {
	const commonAuth = useMutateCommonAuth();
	const queryClient = useQueryClient();

	const auth = getAuth();
	const provider = new GoogleAuthProvider();
	provider.setCustomParameters({
		prompt: 'select_account'
	});
	// useMutation is for create/update/delete
	// to GET an existing user we need to useQuery- why? this will automatically cache the result
	// useMutation hook which requires a promise.
	// pass object with email and password
	// use fireBase createUserWithEmailAndPassword
	return useMutation(
		async ({ email, password, method }) => {
			await commonAuth.mutate({ email, password, method });

			let userCredential;

			if (method === AUTH_METHOD_EMAIL) {
				userCredential = await createUserWithEmailAndPassword(
					auth,
					email,
					password
				);
			} else if (method === AUTH_METHOD_GOOGLE) {
				userCredential = await signInWithPopup(auth, provider);
			}

			return userCredential.user.accessToken;
		},
		{
			onSuccess: (data) => {
				// if this mutation call is successful store the response to querycache
				queryClient.setQueryData(FIREBASEUSERCREDENTIALS, data);
			},
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error.message);
			}
		}
	);
};

export default useMutateAddUser;
