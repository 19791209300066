import { Box, Typography } from '@mui/material';
import { useCallback, useState, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Link, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';

// Our Components
import CaptionBubble from 'components/Caption/CaptionBubble';
import DynamicTabs from 'components/Tabs/DynamicTabs';
import InterestSavingsTab from 'components/FADashboard/InterestSavingsTab';
import NetPresentValueTab from 'components/FADashboard/NetPresentValueTab';
import UpsidePotentialTab from 'components/FADashboard/UpsidePotentialTab';

// Our Query Keys
import { ANALYTICS_LOAN_COMPARISON_PREPOPULATE_TRADELINE_ID } from 'shared/query-keys';

// Our Routes
import { LOAN_INQUIRY_ROUTE } from 'routes';

const TABS_NAMES = [
	{ type: 'Interest Savings' },
	{ type: 'Net Present Value' },
	{ type: 'Upside Potential' }
];

function LoanComparisonTabs({
	analyticsSavingData,
	analyticsAmortizationData,
	analyticsNPVData,
	analyticsUpsidePotentialData
}) {
	const queryClient = useQueryClient();

	const SoraTheme = useTheme();
	const { state } = useLocation();

	const prePopulatedClientData = state?.clientSelected;

	const { disabledGrey } = SoraTheme.palette.primary;

	// Currently selected Tab
	const [tabPanelSelected, setTabPanelSelected] = useState(0);

	const handleTabSelection = useCallback((e, value) => {
		setTabPanelSelected(value);
	}, []);

	const prePopulatedTradeLineId = queryClient.getQueryData(
		ANALYTICS_LOAN_COMPARISON_PREPOPULATE_TRADELINE_ID
	);

	const tabPanelItems = useMemo(
		() => [
			{
				children: (
					<InterestSavingsTab
						analyticsSavingData={analyticsSavingData}
						analyticsAmortizationData={analyticsAmortizationData}
					/>
				)
			},
			{
				children: (
					<NetPresentValueTab analyticsNPVData={analyticsNPVData} />
				)
			},
			{
				children: (
					<UpsidePotentialTab
						analyticsUpsidePotentialData={
							analyticsUpsidePotentialData
						}
					/>
				)
			}
		],
		[
			analyticsSavingData,
			analyticsAmortizationData,
			analyticsNPVData,
			analyticsUpsidePotentialData
		]
	);

	return (
		<Box
			sx={{
				marginTop: 8,
				display: 'flex'
			}}
		>
			<DynamicTabs
				tabItems={TABS_NAMES}
				tabItemSX={{
					padding: 0,
					textTransform: 'none',
					marginRight: 5
				}}
				tabsSX={{
					marginBottom: 4,
					borderBottom: `1px solid ${disabledGrey}`,
					'& .MuiTabs-scrollButtons.Mui-disabled': {
						opacity: 0.3
					},
					maxWidth: 'fit-content'
				}}
				sx={{
					width: '100%'
				}}
				tabPanelItems={tabPanelItems}
				value={tabPanelSelected}
				handleChange={handleTabSelection}
				aria-label="Settings tabs"
				tabGridItemProps={{
					md: 10,
					lg: 8
				}}
			>
				<CaptionBubble>
					<Typography variant="body2" component="div">
						<Typography
							component="span"
							variant="subtitle2"
							fontWeight="bold"
						>
							Price a Loan:
						</Typography>{' '}
						<Typography
							variant="body2"
							component={Link}
							to={LOAN_INQUIRY_ROUTE}
							state={{
								clientSelected: prePopulatedClientData,
								loanInquiryType: 'Mortgage Loan (Refinance)',
								tradelineId: prePopulatedTradeLineId
							}}
						>
							Click here
						</Typography>{' '}
						to price out a loan offer based on this analysis
					</Typography>
				</CaptionBubble>
			</DynamicTabs>
		</Box>
	);
}

export default LoanComparisonTabs;
