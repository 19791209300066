import { useMutation } from 'react-query';

// Our Services
import BoxAPI from 'services/API/BoxAPI';

function useMutateRenameBoxFolder() {
	return useMutation(
		async ({ userId, folderId, advisorFirstName, advisorLastName }) => {
			const body = {
				userId,
				folderId,
				advisorFirstName,
				advisorLastName
			};

			const response = await BoxAPI.patch('update/folder', body);
			return response.data;
		}
	);
}

export default useMutateRenameBoxFolder;
