import { Helmet } from 'react-helmet-async';
import { Backdrop, Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useQueryClient, useQueries } from 'react-query';
import { useNavigate } from 'react-router-dom';

// Our Components
import Alert from 'components/Alert';
import Loader from 'components/Loader';
import FileItem from 'components/FileUpload/FileItem';
import FileUpload from 'components/FileUpload/FileUpload';
import { PrimaryButton, SecondaryButton } from 'components/Button/Button';

// our hooks
import useCleanUpQueries from 'hooks/useCleanUpQueries';

// query keys
import { ERROR_MESSAGE_DATA, FILE_UPLOAD } from 'shared/query-keys';

// Our Routes
import { CLIENT_ONBOARDING_THANKS_ROUTE } from 'routes';

function UploadLoanDocs({
	clientId,
	masterDocumentId,
	newLoanId,
	handleClose
}) {
	const queryClient = useQueryClient();
	const SoraTheme = useTheme();
	const navigate = useNavigate();

	const { primary } = SoraTheme.palette;

	const urlString = window.location.href;
	const onLoanInquiryPage = urlString.includes('loan-request-details');

	const [files, setFiles] = useState([]);
	const [filesRejected, setFilesRejected] = useState([]);
	const [previewImage, setPreviewImage] = useState(null);
	const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [isUploading, setIsUploading] = useState(false);

	useCleanUpQueries([ERROR_MESSAGE_DATA, FILE_UPLOAD]); // When UploadLoanDocs ceases to exist it will clean up these queries

	const hasImageToPreview = previewImage !== null; // if previewImage is null then I know we don't have a preview item selected.
	const queryKeys = useMemo(() => files.map(({ key }) => key), [files]);
	const queryStatuses = useQueries(
		queryKeys.map((currentQueryKey) => ({
			queryKey: [FILE_UPLOAD, currentQueryKey],
			queryFn: () => true,
			enabled: false
		}))
	);

	const isSuccess = useMemo(
		() =>
			queryStatuses.length > 0 &&
			queryStatuses.every(
				(queryStatus) => queryStatus.status === 'success'
			),
		[queryStatuses]
	);

	const isError = useMemo(
		() =>
			queryStatuses.length > 0 &&
			queryStatuses.every(
				(queryStatus) => queryStatus.status === 'error'
			),
		[queryStatuses]
	);

	const isFetching = useMemo(
		() =>
			queryStatuses.length > 0 &&
			queryStatuses.every((queryStatus) => queryStatus.isFetching),
		[queryStatuses, isSuccess, isError]
	);

	// This controls whether the upload button shows upload or spinner
	// if isFetching status changes it will run. isFetching changes if all queries are marked as isFetching
	// if isSuccess changes it will run. isSuccess changes if all are in Success or none are
	// if isError changes it will run. isError changes if all are in error or none are
	useEffect(() => {
		setIsUploading(isFetching);
	}, [isFetching, isSuccess, isError]);

	useEffect(() => {
		const isLoanRequestSuccessful = isSuccess && handleClose;

		if (isLoanRequestSuccessful) {
			handleClose();
		}
	}, [isSuccess]);

	useEffect(() => {
		if (showErrorAlert) {
			setTimeout(() => {
				setShowErrorAlert(false);
			}, 3000);
		}
	}, [showErrorAlert]);

	const updateAcceptedFiles = useCallback((data) => {
		setFiles(data);
	}, []);

	const updateRejectedFiles = useCallback((data) => {
		setFilesRejected(data);
	}, []);

	const removeFiles = useCallback(
		(file) => {
			// cook
			const newFilesList = files.filter(
				({ fileData: currentFile }) => currentFile !== file
			);
			updateAcceptedFiles(newFilesList);
		},
		[files]
	);

	const removeRejectedFiles = (file) => {
		let indexOfFile;
		// for rejected files we store both the File object and its associated error on why it as rejected
		// Because of that we iterate over the list of filesRejected and compare its currentFile to the file that was returned from the callback.
		// eslint-disable-next-line no-restricted-syntax
		for (const [idx, value] of filesRejected.entries()) {
			const { file: currentFile } = value;

			if (file === currentFile) {
				indexOfFile = idx;
				break;
			}
		}
		if (indexOfFile === undefined || indexOfFile === null) return;
		const before = filesRejected.slice(0, indexOfFile);
		const after = filesRejected.slice(indexOfFile + 1);
		updateRejectedFiles([...before, ...after]);
	};

	const acceptedFilesItems = useMemo(
		() =>
			files.map(({ fileData: currentFile, key: uniqueIdentifier }) => (
				<FileItem
					key={uniqueIdentifier}
					file={currentFile}
					setFileToPreview={(fileDetails) => {
						setPreviewImage(fileDetails);
						setIsPreviewEnabled(true);
					}}
					removeFile={removeFiles}
					fileId={uniqueIdentifier}
					isUploading={isUploading}
					masterDocumentId={masterDocumentId}
					newLoanId={newLoanId}
					clientId={clientId}
				/>
			)),
		[files, isUploading]
	);

	const rejectedFileItems = useMemo(
		() =>
			filesRejected.map((currentFile, index) => (
				<FileItem
					key={uuidv4()}
					file={currentFile.file}
					error={currentFile.errors[0]}
					removeFile={removeRejectedFiles}
					isRejectedFile
					index={index}
				/>
			)),
		[filesRejected]
	);

	return (
		<>
			<Helmet>
				<title>Client Onboarding - loan docs</title>
			</Helmet>

			<Box
				sx={{
					height: '100vh',
					width: '100%',
					marginLeft: 2,
					paddingRight: 2
				}}
			>
				{showErrorAlert && <Alert variant="error" />}
				<Typography variant="h1Gascogne" component="h1" marginTop={4}>
					Upload your loan documents below
				</Typography>
				<Typography variant="body2" marginTop={1}>
					Please upload all documentation related to your loan
				</Typography>

				<FileUpload
					files={files}
					filesRejected={filesRejected}
					handleAcceptedFileCallBack={updateAcceptedFiles}
					handleRejectFileCallBack={updateRejectedFiles}
				/>

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
						marginTop: 2,
						marginBottom: 2,
						height: 400,
						overflowY: 'auto'
					}}
				>
					<Typography variant="subtitle1">Files</Typography>
					{files.length < 1 && filesRejected.length < 1 ? (
						<Typography>
							Looks like no files have been uploaded.
						</Typography>
					) : (
						acceptedFilesItems
					)}

					{filesRejected.length > 0 && rejectedFileItems}
				</Box>

				<Box>
					{!isUploading && (
						<Box
							sx={{
								display: 'flex',
								gap: 2
							}}
						>
							<SecondaryButton
								disabled={files.length < 1}
								onClick={() => {
									if (filesRejected.length > 0) {
										queryClient.setQueryData(
											ERROR_MESSAGE_DATA,
											`${filesRejected.length} file(s) will NOT be uploaded.`
										);
										setShowErrorAlert(true);
									}
									if (!isSuccess) {
										setIsUploading(true);
									}
								}}
								sx={{ marginRight: 1 }}
							>
								Upload
							</SecondaryButton>
							{!onLoanInquiryPage && (
								<PrimaryButton
									disabled={!isSuccess}
									onClick={() =>
										navigate(CLIENT_ONBOARDING_THANKS_ROUTE)
									}
								>
									Next
								</PrimaryButton>
							)}
						</Box>
					)}

					{isUploading && <Loader />}
				</Box>

				<Backdrop
					open={isPreviewEnabled}
					onClick={() => {
						setIsPreviewEnabled(false);
						setTimeout(() => {
							setPreviewImage(null);
						}, 100);
					}}
				>
					{hasImageToPreview && (
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Typography
								variant="body2"
								color={primary.contrastText}
								marginBottom="10px"
							>
								File Preview
							</Typography>
							{previewImage.isImageType ? (
								// If the file selected is of type image
								// then render the component as an image
								<Box
									component="img"
									src={previewImage.imagePath}
									sx={{
										width: 'auto',
										height: 400,
										userDrag: 'none',
										WebkitUserDrag: 'none',
										overflow: 'hidden'
									}}
								/>
							) : (
								// If the file selected is NOT an image
								// Then embed it. Currently pdfs can be embeded.
								<Box
									component="embed"
									src={previewImage.imagePath}
									frameBorder="0"
									sx={{
										width: '70vw',
										height: '70vh'
									}}
								/>
							)}
						</Box>
					)}
				</Backdrop>
			</Box>
		</>
	);
}

export default UploadLoanDocs;
