import { Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';

// Our Components
import InviteButtonClientDTA from 'components/Debt-Tolerance/InviteButtonClientDTA';

// our utils
import sentenceCapitalize from 'shared/utils/formatting/sentenceCapitalize';

// Our Constants
import {
	DTS_NOT_STARTED,
	DTS_IN_PROGRESS,
	DTS_COMPLETED
} from 'shared/constants';

/**
 *
 * @param {number} dtsScore
 * @param {string} clientName
 * @returns {string} color
 */
const getComplexDebtToleranceMessage = (dtsScore, clientName) => {
	if (dtsScore === 'NA') return 'na';
	const flooredScore = Math.floor(dtsScore);

	if (flooredScore <= 20)
		return `which indicates a very low tolerance for carrying debt. ${clientName} prefers to avoid debt entirely, prioritizes saving over borrowing, and seeks to pay off any debt as quickly as possible. Consider low-risk financial products that align with their risk-averse nature. If debt is unavoidable, opt for the smallest possible amounts with clear, manageable repayment plans`;
	if (flooredScore <= 40)
		return `which indicates a low tolerance for carrying debt. ${clientName} is cautious with borrowing, prefers to minimize debt but is not entirely opposed to it, and likely prefers stable and predictable financial commitments. If leverage is needed, Sora recommends debt with fixed rates and predictable payments.`;
	if (flooredScore <= 60)
		return `which indicates a moderate tolerance for carrying debt. ${clientName} takes a balanced approach to debt, is willing to take on debt for meaningful purposes such as education, home buying, or business investment, and is comfortable with manageable levels of debt. ${clientName} may be open to strategic borrowing for investments that have the potential to appreciate or provide long-term benefits.`;
	if (flooredScore <= 80)
		return `which indicates a high tolerance for carrying debt. ${clientName} is comfortable with carrying higher levels of debt, and sees debt as a tool for leveraging opportunities. Focus on the potential returns and the ability to service debt without jeopardizing financial stability.`;

	return `which indicates a very high tolerance for carrying debt. ${clientName} is highly comfortable with substantial debt, and sees significant debt as a strategic tool for growth and investment. Encourage a diversified approach to debt financing, leveraging both secured and unsecured debt, and advise on stress-testing financial scenarios to ensure sustainability under various conditions`;
};

function DebtToleranceMessage({ dtsData, isClient, clientData }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const { dtsStatus } = dtsData;

	const dtsNotStarted = dtsStatus === DTS_NOT_STARTED;
	const dtsInProgress = dtsStatus === DTS_IN_PROGRESS;
	const dtsCompleted = dtsStatus === DTS_COMPLETED;

	// is an advisor
	const { firstName } = clientData;

	const capitalizedFirstName = sentenceCapitalize(firstName);

	if (isClient) {
		if (dtsNotStarted) {
			return (
				<>
					<Typography
						variant="body2"
						sx={{ display: 'inline-block' }}
					>
						You have not yet completed a debt tolerance assessment.
						Click
					</Typography>
					<Link to="/dashboard/debt-tolerance-assessment">
						<Typography
							variant="body2"
							sx={{
								display: 'inline-block',
								cursor: 'pointer',
								color: primary.black,
								marginLeft: 0.5,
								marginRight: 0.5,
								textDecoration: 'underline 1px solid black',
								textUnderlineOffset: 2,
								fontWeight: 700
							}}
						>
							here
						</Typography>
					</Link>
					<Typography
						variant="body2"
						sx={{ display: 'inline-block' }}
					>
						to download the Debt Tolerance Assessment.
					</Typography>
				</>
			);
		}

		if (dtsInProgress)
			return (
				<Typography variant="body2">
					We are currently processing your debt tolerance score.
					Please reconnect later to view the result.
				</Typography>
			);

		if (dtsCompleted) {
			const { dtsScore } = dtsData;

			const complexDtsMessage = getComplexDebtToleranceMessage(
				dtsScore,
				capitalizedFirstName
			);

			return (
				<Typography variant="body2">
					You have a score of {dtsScore}, {complexDtsMessage}
				</Typography>
			);
		}
	}

	if (dtsNotStarted) {
		return (
			<Typography
				variant="body2"
				paragraph={false}
				sx={{ display: 'inline' }}
			>
				{capitalizedFirstName} has not yet completed a debt tolerance
				assessment. Click
				<InviteButtonClientDTA clientData={clientData} />
				to download the Debt Tolerance Assessment. Click
				<InviteButtonClientDTA
					clientData={clientData}
					variant="modal"
				/>
				to score the assessment and interpret results.
			</Typography>
		);
	}

	if (dtsInProgress)
		return (
			<Typography variant="body2">
				We are currently processing {capitalizedFirstName}&apos;s debt
				tolerance score. Please reconnect later to view the result.
			</Typography>
		);

	if (dtsCompleted) {
		const { dtsScore } = dtsData;
		const complexDtsMessage = getComplexDebtToleranceMessage(
			dtsScore,
			capitalizedFirstName
		);

		return (
			<Typography
				variant="body2"
				paragraph={false}
				sx={{ display: 'inline' }}
			>
				{capitalizedFirstName} has a score of {dtsScore},{' '}
				{complexDtsMessage} Click
				<InviteButtonClientDTA
					clientData={clientData}
					variant="modal"
				/>
				to rescore the assessment, if your client&apos;s answers have
				changed.
			</Typography>
		);
	}
}

DebtToleranceMessage.propTypes = {
	dtsData: PropTypes.shape({
		clientId: PropTypes.number,
		dtsScore: PropTypes.number,
		dtsStatus: PropTypes.oneOf([
			DTS_COMPLETED,
			DTS_IN_PROGRESS,
			DTS_NOT_STARTED
		])
	}).isRequired,
	isClient: PropTypes.bool.isRequired
};

export default DebtToleranceMessage;
