import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

// Our Components
import AddSingleClientForm from 'components/Forms/clientRelated/AddSingleClientForm';
import Loader from 'components/Loader/index';
import JumboModal from 'components/Modal/JumboModal';
import Modal from 'components/Modal/Modal';
import TextInput from 'components/Input/TextInput';
import { TertiaryButton } from 'components/Button/Button';

// Input Types
import { EMAIL, TEXT } from 'components/Input/Types';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

// Our Query Keys
import {
	SUCCESS_MESSAGE_DATA,
	ERROR_MESSAGE_DATA,
	WEALTH_BOX_CLIENTS,
	WEALTH_BOX_DONE_CLIENTS
} from 'shared/query-keys';

// Our Constants
import { ADVISOR_INTEGRATION, INVITED, ONBOARDED } from 'shared/constants';

// Our Utils
import { simpleValidEmail } from 'shared/utils';
import optimisticStatusUpdate from 'shared/utils/wealthbox/optimisticStatusUpdate';

function TasksModal({
	clientSelected,
	isInvite,
	isOpen,
	setIsOpen,
	setModalMode,
	sendClientInvitation,
	sendClientReInvitation,
	startOnboardingProcess,
	children
}) {
	const queryClient = useQueryClient();
	const { isLoading } = sendClientInvitation;
	const { isLoading: isSendReinviteLoading } = sendClientReInvitation;
	const { data: userProfileData } = useGetProfile();

	const [email, setEmail] = useState(clientSelected?.email ?? '');
	const [clientName, setClientName] = useState(clientSelected?.name ?? '');

	const { pageCallBackNumber, isDone } = clientSelected;

	const QUERY_KEY_TO_USE = isDone
		? WEALTH_BOX_DONE_CLIENTS
		: WEALTH_BOX_CLIENTS;

	const wealthBoxClients = queryClient.getQueryData([
		QUERY_KEY_TO_USE,
		pageCallBackNumber
	]);

	const handleClose = () => {
		setIsOpen(false);
		setModalMode('');
	};

	const handleOnboardingOptimisiticUpdate = () => {
		const { contactList } = wealthBoxClients;

		optimisticStatusUpdate(
			queryClient,
			QUERY_KEY_TO_USE,
			pageCallBackNumber,
			contactList,
			clientSelected.id,
			wealthBoxClients,
			ONBOARDED
		);
	};

	// Fixes memory leak problem
	useEffect(() => () => setEmail(''), []);

	const handleClientInvitation = () => {
		const { id: externalContactId, inviteId } = clientSelected;

		if (inviteId) {
			sendClientReInvitation.mutate(inviteId, {
				onSuccess: () => {
					queryClient.setQueryData(
						SUCCESS_MESSAGE_DATA,
						'Client invite sent!'
					);

					const { contactList } = wealthBoxClients;

					optimisticStatusUpdate(
						queryClient,
						QUERY_KEY_TO_USE,
						pageCallBackNumber,
						contactList,
						externalContactId,
						wealthBoxClients,
						INVITED
					);

					handleClose();
				},
				onError: () => {
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'Client invite failed.'
					);
				}
			});
		} else {
			sendClientInvitation.mutate(
				[
					{
						externalContactId,
						invitedClientEmail: email,
						invitedClientName: clientName
					}
				],
				{
					onSuccess: () => {
						queryClient.setQueryData(
							SUCCESS_MESSAGE_DATA,
							'Client invite sent!'
						);

						const { contactList } = wealthBoxClients;

						optimisticStatusUpdate(
							queryClient,
							QUERY_KEY_TO_USE,
							pageCallBackNumber,
							contactList,
							externalContactId,
							wealthBoxClients,
							INVITED
						);

						handleClose();
					},
					onError: () => {
						queryClient.setQueryData(
							ERROR_MESSAGE_DATA,
							'Client invite failed.'
						);
					}
				}
			);
		}
	};

	const modalTitle = isInvite
		? 'Invite client'
		: "Please provide your client's information";

	const modalSubtitle = isInvite
		? "Enter your client's information below in order to send them an email invitation to join Sora."
		: "Enter your client's information below and they will be added to your account.";

	const inviteButtonText =
		isInvite && clientSelected?.inviteId ? 'Reinvite' : 'Invite';

	const emailIsInvalid = !simpleValidEmail(email);

	if (isInvite)
		return (
			<Modal
				isOpen={isOpen}
				handleClose={handleClose}
				title={modalTitle}
				subtitle={modalSubtitle}
			>
				<>
					<TextInput
						sx={{ marginBottom: 2 }}
						inputProps={{
							'data-test': 'name'
						}}
						type={TEXT}
						label="Client Name"
						value={clientName}
						onChange={setClientName}
						helperText="Please enter the client name"
					/>
					<TextInput
						inputProps={{
							'data-test': 'email'
						}}
						type={EMAIL}
						label="Email Address"
						value={email}
						onChange={setEmail}
						helperText="Please enter a valid email address"
					/>

					{(isLoading || isSendReinviteLoading) && (
						<Loader boxSX={{ paddingTop: 4 }} />
					)}
					{!(isLoading || isSendReinviteLoading) && (
						<TertiaryButton
							isDisabled={emailIsInvalid}
							onClick={handleClientInvitation}
							sx={{ marginTop: 3 }}
						>
							{inviteButtonText}
						</TertiaryButton>
					)}
				</>

				{children}
			</Modal>
		);

	return (
		<JumboModal
			isOpen={isOpen}
			handleClose={handleClose}
			title={modalTitle}
			subtitle={modalSubtitle}
		>
			<AddSingleClientForm
				clientSelected={clientSelected}
				buttonContainerSX={{ marginTop: 2 }}
				userProfileData={userProfileData}
				isModalVersion
				handleClose={handleClose}
				handleOnboardingOptimisiticUpdate={
					handleOnboardingOptimisiticUpdate
				}
				onboardingHandler={startOnboardingProcess}
				onboardingSourceType={ADVISOR_INTEGRATION}
			/>
			{children}
		</JumboModal>
	);
}

export default TasksModal;
