import { useMutation, useQueryClient } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { referralPartnerEndpoint } from 'shared/api-urls';

// Our Query Keys
import { REFERRAL_PARTNERS } from 'shared/query-keys';

function useMutateCreateReferralPartner() {
	const queryClient = useQueryClient();
	return useMutation(
		async ({ referralPartner, advisorId }) => {
			const response = await axiosInstance.post(
				`${referralPartnerEndpoint}/${advisorId}`,
				referralPartner
			);

			return response.data;
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries(REFERRAL_PARTNERS);
			}
		}
	);
}

export default useMutateCreateReferralPartner;
