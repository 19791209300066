import { useTheme } from '@emotion/react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Divider, Grid, Typography } from '@mui/material';

// Our Components
import ErrorAlert from 'components/Alert';
import EmailInput from 'components/Input/EmailInput';
import GoogleAuthButton from 'components/Button/ThirdParty/GoogleAuthButton';
import LatencyAlertWarning from 'components/Alert/LatencyAlertWarning';
import { PrimaryButton, SecondaryButton } from 'components/Button/Button';
import PasswordLessModal from 'components/Modal/PasswordLessModal';
import PasswordTextInput from 'components/Input/PasswordTextInput';

// Our Hooks
import useMutateLoginUser from 'hooks/auth/useMutateLoginUser';
import useMutateGetMagicToken from 'hooks/passwordless/useMutateGetMagicToken';

// Constants
import {
	AUTH_METHOD_EMAIL,
	AUTH_METHOD_GOOGLE
} from 'shared/constants/auth/authConstants';

function FullLoginForm({ enableWarning }) {
	const SoraTheme = useTheme();
	// For our emails, we direct users to the /login page with the queryParam
	// app.sorafinance.com/login?email=emailaddress@gmail.com
	// if this query param is present, we pull it out of the url and pre-populate it
	// in the email input field
	const urlString = window.location.search;
	const urlParams = new URLSearchParams(urlString);
	const encodedEmailFromUrl = urlParams.get('email');

	// This function is to check to see if the email pulled out of the URL is encoded
	const checkEmailEncoding = () => {
		if (encodedEmailFromUrl !== null) {
			try {
				// If able to correct decode this return that value.
				return window.atob(encodedEmailFromUrl);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
				// If not able to decode email correctly, return empty string.
				return '';
			}
		}
		return '';
	};

	// this will be either the decoded email address or a blank string
	const decodedEmailFromUrl = checkEmailEncoding();

	const [email, setEmail] = useState(decodedEmailFromUrl);
	const [password, setPassword] = useState('');
	const [isUsingGooglePopUp, setIsUsingGooglePopUp] = useState(false);

	// Modal State
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalEmail, setModalEmail] = useState('');
	const loginUser = useMutateLoginUser();
	const getMagicToken = useMutateGetMagicToken();

	const { error: loginUserError, isLoading } = loginUser;

	// Handle field change
	const handleEmailChange = (value) => {
		setEmail(value);
	};

	const handlePasswordChange = (value) => {
		setPassword(value);
	};

	// Defining useNavigate from react router dom
	const navigate = useNavigate();

	// Handle login form submit,
	// Sign into account w/ email and password
	const signInWithEmail = (e) => {
		e.preventDefault();
		loginUser.mutate(
			{
				email,
				password,
				method: AUTH_METHOD_EMAIL
			},
			{
				onSuccess: () => {
					navigate('/routing-user', { replace: true });
				}
			}
		);
	};

	// Handle google login,
	// Login with existing google account
	const useSignInWithGoogle = (event) => {
		event.preventDefault();
		event.stopPropagation();
		loginUser.mutate(
			{
				method: AUTH_METHOD_GOOGLE
			},
			{
				onSuccess: () => {
					navigate('/routing-user', { replace: true });
				}
			}
		);
	};

	return (
		<>
			{enableWarning && <LatencyAlertWarning />}

			<Grid item xs={9} md={10}>
				<Typography variant="h1Gascogne" gutterBottom>
					Login
				</Typography>
			</Grid>
			<Box
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={signInWithEmail}
			>
				{loginUserError && <ErrorAlert variant="error" />}
				<Grid item xs={12} md={8}>
					{/* Email */}
					<EmailInput
						email={email}
						handleEmailChange={handleEmailChange}
					/>

					<PasswordTextInput
						password={password}
						handlePasswordChange={handlePasswordChange}
						sx={{
							marginTop: 4,
							marginBottom: 2
						}}
					/>

					<Box
						sx={{
							display: 'flex',
							gap: 9
						}}
					>
						{/* Forgot password link */}
						<Link
							to="/forgot"
							style={{
								color: SoraTheme.palette.primary.black,
								textDecorationColor:
									SoraTheme.palette.primary.black
							}}
						>
							<Typography
								variant="subtitle2"
								sx={{
									width: '100%'
								}}
							>
								Forgot your password?
							</Typography>
						</Link>
					</Box>

					<PrimaryButton
						onClick={signInWithEmail}
						data-test="login_button"
						type="submit"
						disabled={
							email === '' ||
							!email.includes('.') ||
							!email.includes('@') ||
							password === '' ||
							password.length < 8
						}
						sx={{
							marginTop: 4,
							marginBottom: 4,
							display: 'block',
							width: '100%',
							paddingTop: 2,
							paddingBottom: 2
						}}
					>
						Log in
					</PrimaryButton>

					<Divider>
						<Box
							sx={{
								border: 'thin solid rgba(0, 0, 0, 0.12)',
								borderRadius: 4,
								padding: 0.5,
								paddingLeft: 2,
								paddingRight: 2
							}}
						>
							or
						</Box>
					</Divider>

					<Box
						sx={{
							marginTop: 4,
							display: 'flex',
							flexDirection: 'column',
							gap: 2
						}}
					>
						<SecondaryButton
							sx={{ width: '100%', height: 38 }}
							onClick={() => setIsModalOpen(true)}
						>
							Email me a login link
						</SecondaryButton>

						<GoogleAuthButton
							onClick={useSignInWithGoogle}
							disabled={isLoading}
							isLoading={isLoading}
							text="Log in with Google"
						/>
					</Box>
				</Grid>
			</Box>
			<PasswordLessModal
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				setModalEmail={setModalEmail}
				modalEmail={modalEmail}
				getMagicToken={getMagicToken}
			/>
		</>
	);
}

export default FullLoginForm;
