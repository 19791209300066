import GoogleIcon from '@mui/icons-material/Google';

// Our Components
import Loader from 'components/Loader';
import { SecondaryButton } from 'components/Button/Button';

function GoogleAuthButton({ isLoading, onClick, disabled, text }) {
	return (
		<SecondaryButton
			onClick={onClick}
			disabled={disabled}
			startIcon={
				isLoading ? (
					<Loader
						size={20}
						loaderSX={{ color: 'black', opacity: 0.2 }}
					/>
				) : (
					<GoogleIcon />
				)
			}
			sx={{
				width: '100%'
			}}
		>
			{text}
		</SecondaryButton>
	);
}

export default GoogleAuthButton;
