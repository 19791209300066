import PropTypes from 'prop-types';

// Our Components
import TextInput from 'components/Input/TextInput';

function EmailInput({ email, handleEmailChange, isValidEmail }) {
	const isEmailInvalid =
		email === '' || !email.includes('.') || !email.includes('@');

	const helperText = isEmailInvalid
		? 'Please enter a valid email address'
		: '';

	return (
		<TextInput
			id="email"
			label="Email"
			type="email"
			onChange={handleEmailChange}
			inputProps={{
				'data-test': 'email-input'
			}}
			value={email}
			error={isValidEmail || isEmailInvalid}
			helperText={helperText}
			sx={{
				marginTop: 4,
				marginBottom: 2
			}}
		/>
	);
}

EmailInput.propTypes = {
	email: PropTypes.string.isRequired,
	handleEmailChange: PropTypes.func.isRequired,
	isValidEmail: PropTypes.bool
};

EmailInput.defaultProps = {
	isValidEmail: null
};

export default EmailInput;
