// @ts-check
// constants
import { LIABILITY_CONFIDENCE_LEVEL_MAPPING } from 'shared/constants';

/**
 * @typedef {'Manual' | 'Third Party'} DataSource
 * @typedef {'method' | 'financial_institution' | 'public_data' | null} InterestRateSource
 */

const UNKNOWN_DATA_SOURCE = 'unknown';
const RATE_MANUALLY_UPDATED = 'rateManuallyUpdated';
const MANUAL_DATA_SOURCE = 'Manual';
const PARSED_LIABILITY_CONFIDENCE = JSON.parse(
	LIABILITY_CONFIDENCE_LEVEL_MAPPING
);

/**
 * @param {InterestRateSource} interestRateSource
 * @param {boolean} rateManuallyUpdated
 * @param {DataSource} dataSource
 * @returns {number}
 */
const getLiabilityConfidence = (
	interestRateSource,
	rateManuallyUpdated,
	dataSource
) => {
	if (dataSource === MANUAL_DATA_SOURCE)
		return PARSED_LIABILITY_CONFIDENCE.Manual;

	if (rateManuallyUpdated)
		return PARSED_LIABILITY_CONFIDENCE[RATE_MANUALLY_UPDATED];

	// we are only checking interest rate source now
	if (interestRateSource in PARSED_LIABILITY_CONFIDENCE)
		return PARSED_LIABILITY_CONFIDENCE[interestRateSource];

	// This is the default which the env is currently pointing to Low
	return PARSED_LIABILITY_CONFIDENCE[UNKNOWN_DATA_SOURCE];
};

export default getLiabilityConfidence;
