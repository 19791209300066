// New super list of routes
export const ADVISOR_ROUTE = 'advisor';
export const ADD_CLIENT_ROUTE = '/add-client';
export const CASH_OUT_ROUTE = 'cash_out';
export const CLIENT_ONBOARDING_ADDITIONAL_DETAILS_ROUTE =
	'/client-onboarding/user-details';
export const CLIENT_ONBOARDING_ASSETS_ROUTE = '/client-onboarding/assets/';
export const CLIENT_ONBOARDING_INCOME_ROUTE = '/client-onboarding/income';
export const CLIENT_ONBOARDING_LIABILITIES_IDENTIFIED_ROUTE =
	'/client-onboarding/liabilities/identified';
export const CLIENT_ONBOARDING_PARTIAL_ROUTE = '/client-onboarding/liabilities';
export const CLIENT_ONBOARDING_REVIEW_ROUTE = '/client-onboarding/review';
export const CLIENT_ONBOARDING_UNABLE_TO_VERIFY_ROUTE =
	'/client-onboarding/unable-to-verify';
export const CLIENT_ONBOARDING_THANKS_ROUTE = '/client-onboarding/thankyou';

export const CONTACT_US_ROUTE = 'contact-us';
export const DASHBOARD_ROUTE = '/dashboard';
export const ERROR_ROUTE = '*';
export const FA_ONBOARDING_ADDITIONAL_DETAILS_ROUTE =
	'/fa-onboarding/additional-details';
export const FA_ONBOARDING_THANKS_ROUTE = '/fa-onboarding/thanks';
export const FORGOT_PASSWORD_ROUTE = 'forgot';
export const INVITE_THANKS_ROUTE = '/invite/thanks';
export const LIABILITY_ROUTE = 'liability';
export const LOAN_INQUIRY_ROUTE = '/price-a-loan';
export const LOAN_INQUIRY_DETAILS_ROUTE = '/loan-request-details';
export const LOGIN_OTP_ROUTE = 'login/otp';
export const LOGIN_ROUTE = '/login';
export const MESSAGES_ROUTE = '/dashboard/messages';
export const NEW_LOAN_ROUTE = 'new_loan';
export const NEW_LOAN_HOME_ROUTE = '/new-loan/home-loan-questionnaire';
export const NEW_LOAN_AUTO_ROUTE = '/new-loan/auto-loan-questionnaire';
export const PROSPECTIVE_CLIENT_ROUTE = 'dashboard/prospective-client';
export const REFINANCE_ROUTE = 'refinance';
export const REPORTS_ROUTE = '/dashboard/report';
export const ROOT_ROUTE = '/';
export const ROUTING_USER_ROUTE = '/routing-user';
export const SELECT_NEW_LOAN_ROUTE = '/select-loan/new-loan';
export const SETTINGS_ROUTE = '/settings';
export const SIGNUP_ROUTE = 'signup';
export const SORA_FOR_EVERYBODY_ROUTE = '/sora-for-everybody';
export const SUPER_ADMIN_ROUTE = 'super-admin';
export const UNSUBSCRIBE_ROUTE = 'unsubscribe';
export const VARIABLE_CLIENT_ID_ROUTE = ':clientId';
export const VARIABLE_TRADELINE_ROUTE = ':tradelineId';
export const VARIABLE_ADVISOR_TRADELINE_ROUTE = 'liability/:tradelineId';

// Client Onboarding Additional(manual) Routes
export const AUTO_LIABILITY_ADDITIONAL_ROUTE =
	'/client-onboarding/liabilities/additional/auto';
export const CREDIT_CARD_LIABILITY_ADDITIONAL_ROUTE =
	'/client-onboarding/liabilities/additional/creditcard';
export const HOME_LIABILITY_ADDITIONAL_ROUTE =
	'/client-onboarding/liabilities/additional/mortgage';
export const STUDENT_LIABILITY_ADDITIONAL_ROUTE =
	'/client-onboarding/liabilities/additional/student';
export const PERSONAL_LIABILITY_ADDITIONAL_ROUTE =
	'/client-onboarding/liabilities/additional/personal';
export const LIABILITY_ADDITIONAL_ROUTE =
	'/client-onboarding/liabilities/additional/';

// Other Loan Route
export const OTHER_LOAN_ROUTE = '/client-onboarding/liabilities/other';

export const CLIENT_INTRO_ROUTE = '/client-onboarding/intro'; // The page with methodfi
export const CLIENT_REVIEW_ROUTE = '/client-onboarding/review'; // final page in flow
export const CLIENT_SORAWALLET_ROUTE = '/sora-wallet-overview';
export const ADVISOR_WALLET_ROUTE = '/sora-wallet-overview/advisor/';
export const ADVISOR_DYNAMIC_WALLET_ROUTE =
	'/sora-wallet-overview/advisor/:clientId';

// Wallet
export const CLIENT_WALLET_LIABILITY = '/sora-wallet-overview/liability/';
export const ADVISOR_WALLET_LIABILITY = '/sora-wallet-overview/advisor/';

// Offers
export const NEW_LOAN_OFFER_ROUTE = '/offers/new_loan';
export const CASH_OUT_OFFER_ROUTE = '/offers/cash_out';
export const REFINANCE_OFFER_ROUTE = '/offers/refinance';

// Dashboard Pages
export const PROSPECTIVE_CLIENT_INTRO_PAGE = '/dashboard/prospective-client';
export const ADVISOR_ANALYTICS = '/dashboard/analytics';
export const DTA_ROUTE = '/dashboard/debt-tolerance-assessment';
export const ADVISOR_ANALYTICS_INTRO = '/dashboard/analytics/intro';

// Update Liability Dynamic Route
export const DYNAMIC_UPDATE_LIABILITY_ROUTE =
	'/update/:tradelineType/:tradelineId';

export const ADVISOR_DYNAMIC_UPDATE_LIABILITY_ROUTE =
	'/update/:clientId/:tradelineType/:tradelineId';

// Adding new liability routes
export const CLIENT_NEW_LOAN_SELECTION_ROUTE = '/add-new/';
export const ADVISOR_BASE_NEW_LOAN_SELECTION_ROUTE = '/advisor-add-new/';
export const ADVISOR_NEW_LOAN_SELECTION_ROUTE = '/advisor-add-new/:clientId';

export const CLIENT_ADD_NEW_AUTO_ROUTE = '/add-new/auto';
export const CLIENT_ADD_NEW_MORTGAGE_ROUTE = '/add-new/mortgage';
export const CLIENT_ADD_NEW_STUDENT_ROUTE = '/add-new/student';
export const CLIENT_ADD_NEW_PERSONAL_ROUTE = '/add-new/personal';
export const CLIENT_ADD_NEW_CREDIT_CARD_ROUTE = '/add-new/creditcard';
export const CLIENT_ADD_NEW_COMMERCIAL_LOAN_ROUTE = '/add-new/commercial';

export const ADVISOR_ADD_NEW_AUTO_ROUTE = '/advisor-add-new/:clientId/auto';
export const ADVISOR_ADD_NEW_MORTGAGE_ROUTE =
	'/advisor-add-new/:clientId/mortgage';
export const ADVISOR_ADD_NEW_STUDENT_ROUTE =
	'/advisor-add-new/:clientId/student';
export const ADVISOR_ADD_NEW_PERSONAL_ROUTE =
	'/advisor-add-new/:clientId/personal';
export const ADVISOR_ADD_NEW_CREDIT_CARD_ROUTE =
	'/advisor-add-new/:clientId/creditcard';
export const ADVISOR_ADD_NEW_COMMERCIAL_LOAN_ROUTE =
	'/advisor-add-new/:clientId/commercial';

// Third Party
export const WEALTH_BOX_REDIRECT_URI = 'integration/wealthbox/redirected';

// Dts magic link
export const DTS_ROUTING_USER = '/dts-routing-user';

// Loan Pipeline
export const LOAN_PIPELINE_ROUTE = '/loan-pipeline';
export const ADVISOR_INTEGRATIONS_ROUTE = '/advisor/integrations';

// Support
export const SUPPORT_ROUTE = '/support';
