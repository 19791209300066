import { useMutation, useQueryClient } from 'react-query';

// Our axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { saveDtsScoreEndpoint } from 'shared/api-urls';

// Our Query Keys
import { DTS_STATUS, ERROR_MESSAGE_DATA } from 'shared/query-keys';

function useMutateSaveDtsScore() {
	const queryClient = useQueryClient();

	return useMutation(
		async ({ clientId, dtsScore }) => {
			const response = await axiosInstance.post(
				`${saveDtsScoreEndpoint}${clientId}`,
				{ dtsScore }
			);

			return response.data;
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries(DTS_STATUS);
			},

			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error);
			}
		}
	);
}

export default useMutateSaveDtsScore;
