import { Helmet } from 'react-helmet-async';
import { Box, Grid, Typography } from '@mui/material';

// Our Components
import { PrimaryButton, SecondaryButton } from 'components/Button/Button';

// Our Routes
import { ADD_CLIENT_ROUTE } from 'routes/index';

function ClientAddedThanksPage() {
	return (
		<Box sx={{ height: '100%', width: '100%', marginLeft: 2 }}>
			<Helmet>
				<title>Client invited</title>
			</Helmet>

			<Grid item xs={12}>
				<Typography
					variant="h1Gascogne"
					component="h1"
					gutterBottom
					sx={{
						marginTop: 8
					}}
				>
					Thank you! You have added a client.
				</Typography>

				<Typography
					variant="body1"
					gutterBottom
					component="div"
					sx={{
						marginTop: 4
					}}
				>
					Sora will reach out once your dashboard has been updated.
					You can edit this information at any time by selecting a
					client on your dashboard.
				</Typography>

				<SecondaryButton
					href={ADD_CLIENT_ROUTE}
					sx={{
						marginTop: 4
					}}
				>
					Add another client
				</SecondaryButton>

				<PrimaryButton
					href="/dashboard"
					sx={{
						marginTop: 4,
						marginLeft: 2
					}}
				>
					Back to dashboard
				</PrimaryButton>
			</Grid>
		</Box>
	);
}

export default ClientAddedThanksPage;
